import React from 'react';
import PropTypes from 'prop-types';

const Seo_Widget = ({ item }) => {
	const content = { __html: item?.festivalDescription };
	return (
		<>
			<section className='Ad-Section'>
				<div className='container'>
					<div className='row AdSectionContainer d-flex align-items-center justify-content-center'>
						<div className='col-lg-8 d-flex justify-content-center w-100'>
							<a href='/ticketdetail'>
								<button className='btn-txt1 ticketButtonMain'>
									{' '}
									Click here to GET TICKETS{' '}
								</button>
							</a>
						</div>
						<div className='col-lg-8 col-sm-12 '>
							<div className='row d-flex justify-content-center'>
								<div className='col-lg-8 d-flex justify-content-center px-4 py-3'>
									<img
										src={item?.festivalFunImage}
										alt=''
										srcSet=''
										width={'100%'}
									/>
								</div>
							</div>
							<div className='card-content'>
								<span
									dangerouslySetInnerHTML={{
										__html: item?.festivalTitle,
									}}></span>
								<span
									className='card-widget-description'
									dangerouslySetInnerHTML={content}></span>
								<div className='buttonwrap'></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Seo_Widget;

Seo_Widget.propTypes = {
	item: PropTypes.object.isRequired,
};

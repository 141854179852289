import '../Styles/EventDetails.css';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { EventPageDetails } from '../Redux/Slice';
import Spinner from '../component/Spinners';
import TicketBannerImage from '../assets/TicketBanner.png';
import NoEventList from '../assets/NoEvent.jpg';
import CalenderImage from '../assets/EventDetail/calender.png';
import { convertDateTime } from '../TimeConvert';

const EventDetails = () => {
	const { id } = useParams();

	const dispatch = useDispatch();
	const { EventDetailsData, EventPageList } = useSelector((state) => state.festivWebsite);
	const [Loading, SetLoading] = useState(false);

	useEffect(() => {
		dispatch(EventPageDetails(id));
	}, [id, dispatch]);

	useEffect(() => {
		if (EventDetailsData === null) {
			SetLoading(false);
		}
	}, [EventDetailsData]);

	const [weatherData, setWeatherData] = useState(null);

	const applyStyles = {
		fontFamily: EventPageList?.fontFamily,
		color: EventPageList?.fontColor,
	};

	const lat = parseFloat(EventDetailsData?.eventList[0]?.eventLocation?.latitude);
	const lng = parseFloat(EventDetailsData?.eventList[0]?.eventLocation?.longitude);

	useEffect(() => {
		const fetchWeatherData = async () => {
			const apiKey = '6304ab9c45f30c9e827efd1924c5085b';
			const units = 'metric';
			const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lng}&units=${units}&appid=${apiKey}`;
			const response = await fetch(url);

			const data = await response.json();

			setWeatherData(data);
		};

		if (lat && lng) {
			fetchWeatherData();
		}
	}, [EventDetailsData, lat, lng]);

	const mapStyles = {
		height: '381px',
		width: '100%',
		borderRadius: ' 25px',
	};

	const content = { __html: EventDetailsData?.eventList[0]?.description };
	const center = {
		lat: parseFloat(EventDetailsData?.eventList[0]?.eventLocation?.latitude),
		lng: parseFloat(EventDetailsData?.eventList[0]?.eventLocation?.longitude),
	};

	function celsiusToFahrenheit(celsius) {
		const val = (celsius * 9) / 5 + 32;
		return val?.toFixed(0);
	}

	return (
		(Loading && <Spinner />) || (
			<div className='eventMainDetail'>
				<Container fluid className='eventBlur' style={applyStyles}>
					<Container className='d-flex eventDetailContainer flex-column'>
						<Row className='eventDetailbanner'>
							<img
								src={
									EventDetailsData?.BannerImage
										? EventDetailsData?.BannerImage
										: TicketBannerImage
								}
								alt='bannerevent'></img>
						</Row>
						<Row className='d-flex justify-content-center align-items-center '>
							<Col
								lg={5}
								className='d-flex justify-content-center align-items-center my-2 '>
								<a href='/ticketdetail'>
									<button className='btn-txt1 ticketButtonMain'>
										{' '}
										Click here to GET TICKETS{' '}
									</button>
								</a>
							</Col>
						</Row>
						<Row className='mb-5 mt-5 EventDetailContentMain'>
							{EventDetailsData === null ? (
								<>
									<img
										src={NoEventList}
										alt='noevent'
										className='EventDetailImg'
									/>
								</>
							) : (
								<Col lg={11} className='EventDetailContent EventDetailContentClo'>
									<Row>
										<Col lg={8} md={12}>
											<Row>
												<Col lg={12} className='py-4 EventDetailHeading'>
													<h1>
														{EventDetailsData?.eventList[0]?.event?.eventName
															.charAt(0)
															.toUpperCase() +
															EventDetailsData?.eventList[0]?.event?.eventName.slice(
																1,
															)}
													</h1>
													<div>
														<h6>
															{
																EventDetailsData?.eventList[0]
																	?.eventLocation?.locationName
															}{' '}
															,{' '}
															{
																EventDetailsData?.eventList[0]
																	?.eventLocation?.address
															}
														</h6>
													</div>
													<div className='d-flex flex-column py-2'>
														<h6>Event Scheduled Date & Time</h6>
														{EventDetailsData?.eventList[0]?.event?.eventSchedule?.map(
															(items, eventIndex) => (
																<div
																	className='eventDetailPara'
																	key={eventIndex}>
																	{/* <span className='d-flex'>
																		<h6 className='px-2 mb-0'>
																			From{' '}
																		</h6>{' '}
																		<span className='text-dark'>
																			{
																				items?.eventDateAndTimeFrom.split(
																					' ',
																				)[0]
																			}{' '}
																			{
																				items?.eventDateAndTimeFrom.split(
																					' ',
																				)[1]
																			}
																		</span>
																		<h6 className='px-2 mb-0'>
																			To{' '}
																		</h6>
																		<span>
																			{
																				items?.eventDateAndTimeTo.split(
																					' ',
																				)[0]
																			}{' '}
																			{
																				items?.eventDateAndTimeTo.split(
																					' ',
																				)[1]
																			}
																		</span>
																	</span> */}
																	<div className='eventDetailDateImg'>
																		<img
																			src={CalenderImage}
																			alt=''
																		/>
																	</div>
																	<div className='eventDetailDateList'>
																		<div
																			dangerouslySetInnerHTML={{
																				__html: convertDateTime(
																					items?.eventDateAndTimeFrom,
																					items?.eventDateAndTimeTo,
																				),
																			}}></div>
																		{/* <div>
																			<h6 className='textblue'>
																				{timeConvert(
																					items?.eventDateAndTimeFrom,
																				)}{' '}
																			</h6>
																		</div>
																		<div>
																			<h6 className='textRed'>
																				{
																					items?.eventDateAndTimeFrom.split(
																						' ',
																					)[1]
																				}
																			</h6>
																		</div>
																		<div>
																			<h6 className='textblue'>To</h6>
																		</div>
																		<div>
																			<h6 className='textRed'>
																				{
																					items?.eventDateAndTimeTo.split(
																						' ',
																					)[1]
																				}
																			</h6>
																		</div> */}
																	</div>
																</div>
															),
														)}
													</div>
													<div className='py-2'>
														<span className='fw-bold eventdecriptionHead'>
															Event Description
														</span>
														<div className='eventDescription'>	
															{
																EventDetailsData?.eventList[0]
																	?.event?.description
															}
														</div>
													</div>

													{EventDetailsData?.eventList[0]?.description ===
														'<p><br></p>' ||
														(EventDetailsData?.eventList[0]
															?.description !== '' && (
															<div className='py-2'>
																<div className='fw-bold eventAannouncement'>
																	Event Anouncement
																</div>
																<div
																	dangerouslySetInnerHTML={
																		content
																	}></div>
															</div>
														))}
												</Col>
											</Row>
										</Col>
										{/* <Col lg={4} md={12} className='py-4'>
											<Row>
												<Col lg={12}>
													<section className='EventMapSection'>
														<GoogleMap
															center={center}
															zoom={4}
															mapContainerStyle={mapStyles}
															draggable={true}>
															<Marker
																position={{
																	lat: parseFloat(
																		EventDetailsData
																			?.eventList[0]
																			?.eventLocation
																			?.latitude,
																	),
																	lng: parseFloat(
																		EventDetailsData
																			?.eventList[0]
																			?.eventLocation
																			?.longitude,
																	),
																}}>
																<InfoWindow
																	position={{
																		lat: parseFloat(
																			EventDetailsData
																				?.eventList[0]
																				?.eventLocation
																				?.latitude,
																		),
																		lng: parseFloat(
																			EventDetailsData
																				?.eventList[0]
																				?.eventLocation
																				?.longitude,
																		),
																	}}>
																	<>
																		<div className='fs-6  fw-normal'>
																			{' '}
																			{
																				EventDetailsData
																					?.eventList[0]
																					?.eventLocation
																					?.locationName
																			}
																		</div>
																		{weatherData != null && (
																			<>
																				<h6>
																					Temperature:{' '}
																					{celsiusToFahrenheit(
																						weatherData
																							?.main
																							?.temp,
																					)}
																					°F
																				</h6>
																				<h6>
																					{' '}
																					{
																						weatherData
																							?.weather[0]
																							?.description
																					}
																				</h6>
																			</>
																		)}
																	</>
																</InfoWindow>
															</Marker>
														</GoogleMap>
													</section>
												</Col>
											</Row>
										</Col> */}
									</Row>
									<Row className='EventTicketContentTick'>
										<Col lg={12} className='EventTicketContentTick'>
											<div className='EventTicketSection'>
												{EventDetailsData?.eventList[0]?.ticket?.length >
												0 ? (
													<Row className='mt-4 mb-4 '>
														<Col lg={7}>
															<h3 className='fw-bold'>Tickets</h3>
														</Col>
														{EventDetailsData?.eventList[0]?.ticket?.map(
															(item, index) => (
																<Col
																	lg={7}
																	className='mt-2 mb-2 mx-3 d-flex flex-column justify-content-start align-items-start'
																	key={index}>
																	<h5 className='eventDetailBtn'>
																		{item?.ticketName}
																	</h5>
																</Col>
															),
														)}
														<Col
															lg={4}
															className='EventTicketContentCol'>
															<Link
																to={`/eventdetails/${id}/ticketdetails`}
																className='EventBuyBtn'>
																Buy Ticket
															</Link>
														</Col>
													</Row>
												) : (
													<Row className='mt-4 mb-4 mx-5'>
														<h3 className='fw-bold'>No Tickets</h3>
													</Row>
												)}
											</div>
										</Col>
									</Row>
								</Col>
							)}
						</Row>
					</Container>
				</Container>
			</div>
		)
	);
};

export default EventDetails;

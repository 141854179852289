export const errTitle = 'Error!';
export const scc = 'Success';
export const poscent = 'center';
export const posTop = 'top-end';
export const errIcon = 'error';
export const sccIcon = 'success';
export const BtnCanCel = 'Cancel';
export const BtnGreat = 'Great!';
export const successPayMessage = 'Payment Successfully, Please Check Your Email For E-Ticket';
export const errorPayMessage = 'Payment Rejected,try again..';
export const PaymentErrTitle = 'Payment Status';

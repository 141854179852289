import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../Styles/Ticket.css';
import { useDispatch, useSelector } from 'react-redux';
import {
	EventPageData,
	EventPageDetails,
	LocateTicketing,
	errorMessage,
	successMessage,
} from '../Redux/Slice';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import LocationImage from '../assets/EventDetail/Vector.png';
import CalenderImage from '../assets/EventDetail/calender.png';
import TicketImage from '../assets/EventDetail/TicketSheet.png';
import ReCAPTCHA from 'react-google-recaptcha';
import NoTicket from '../assets/NoTicket.jpg';
import Swal from 'sweetalert2';
import { errTitle, scc, poscent, posTop, errIcon, sccIcon, BtnCanCel, BtnGreat } from '../Constant';
import PropTypes from 'prop-types';
import TicketBannerImage from '../assets/TicketBanner.png';
import { convertDateTime } from '../TimeConvert';

const MydModalWithGrid = ({ show, item, onHide }) => {
	const navigate = useNavigate();
	const handleConfirm = () => {
		sessionStorage.setItem('cart', JSON.stringify(item));
		onHide(false);
		navigate('/buyticket');
	};

	return (
		<Modal
			show={show}
			size='lg'
			onHide={onHide}
			aria-labelledby='contained-modal-title-vcenter'>
			<Modal.Header closeButton onHide={onHide}>
				<Modal.Title id='contained-modal-title-vcenter'>Add to Checkout</Modal.Title>
			</Modal.Header>
			<Modal.Body className='grid-example'>
				<div className='table-responsive-sm'>
					<table className='table'>
						<thead>
							<tr className='ticketModaltr'>
								<th scope='col' className='text-center'>
									Event Name
								</th>
								<th scope='col' className='text-center'>
									Ticket Name
								</th>
								<th scope='col' className='text-center'>
									Ticket
								</th>
								<th scope='col' className='text-center'>
									Quantity
								</th>
								<th scope='col' className='text-center'>
									Price(per person)
								</th>
							</tr>
						</thead>
						<tbody>
							{item?.map((list, index) => (
								<tr key={index}>
									<td className='text-center'>{list?.eventName}</td>
									<td className='text-center'>{list?.ticketname}</td>
									<td className='text-center'>{list?.fees?.ticketType}</td>
									<td className='text-center'>{list?.quantity}</td>
									<td className='text-center'>
										$ {list?.fees?.ticketPrice?.price}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Modal.Body>
			<Modal.Footer className='d-flex justify-content-between'>
				<button
					className='ticketDetailBtn '
					onClick={() => {
						onHide(false);
					}}>
					Continue shopping{' '}
				</button>
				<button onClick={handleConfirm} className='ticketDetailBtn '>
					Checkout Now
				</button>
			</Modal.Footer>
		</Modal>
	);
};

MydModalWithGrid.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	item: PropTypes.array.isRequired,
};

const LocateTicket = ({ show, onHide }) => {
	const captchaRef = useRef(null);

	const dispatch = useDispatch();
	const { success, error } = useSelector((state) => state.festivWebsite);

	const [email, setEmail] = useState('');
	const [Error, setError] = useState('');

	const handleChange = (e) => {
		setEmail(e.target.value);

		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
			setError('Not a Valid Email');
		} else {
			setError('');
		}
	};

	const handleConfirm = (e) => {
		e.preventDefault();
		const token = captchaRef.current.getValue();
		dispatch(LocateTicketing({ token, email }));
		captchaRef.current.reset();
	};

	const Notification = useCallback(
		(val, tit, pos, ico, btn) => {
			Swal.fire({
				position: `${pos}`,
				title: `${tit}`,
				text: `${val}`,
				icon: `${ico}`,
				confirmButtonText: `${btn}`,
			});
			dispatch(errorMessage({ errors: '' }));
			dispatch(successMessage({ successess: '' }));
			onHide(false);
		},
		[dispatch, onHide],
	);

	useEffect(() => {
		if (success === 'Please check your Email for your E-Ticket' || 'All Tickets are Expired') {
			success && Notification(success, scc, posTop, sccIcon, BtnGreat);
		}
		if (error !== 'Please try again') {
			error && Notification(error, errTitle, poscent, errIcon, BtnCanCel);
			onHide(false);
		}
	}, [error, success, Notification, onHide]);

	return (
		<Modal
			show={show}
			size='md'
			onHide={onHide}
			aria-labelledby='contained-modal-title-vcenter'>
			<Modal.Header className='locateHeader' closeButton onHide={onHide}>
				<Modal.Title id='contained-modal-title-vcenter'>LOCATE YOUR E-TICKET</Modal.Title>
			</Modal.Header>
			<Modal.Body className='grid-example'>
				<Container>
					<div className='captcha'>
						<form onSubmit={handleConfirm}>
							<label htmlFor='name' className='fw-bold' lang='en'>
								Email Used For Ticket Purchase
							</label>
							<input
								type='text'
								id='name'
								className='input'
								onChange={(e) => {
									handleChange(e);
								}}
							/>
							<p className='text-danger'>{Error}</p>
							<ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
							<button onClick={(e) => handleConfirm(e)}>Submit</button>
						</form>
					</div>
				</Container>
			</Modal.Body>
		</Modal>
	);
};
LocateTicket.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
};

const Ticket = () => {
	const dispatch = useDispatch();
	const { id } = useParams();

	const [locationList, SetLocationList] = useState('');
	const [EventList, SetEventList] = useState('');
	const [modalShow, setModalShow] = useState(false);
	const [Locate, setLocate] = useState(false);

	useEffect(() => {
		if (id) {
			dispatch(EventPageDetails(id));
		} else {
			dispatch(EventPageData());
		}
	}, [id, dispatch]);

	const { EventDetailsData, EventPageList } = useSelector((state) => state.festivWebsite);

	const applyStyles = {
		fontFamily: EventPageList?.fontFamily,
		color: EventPageList?.fontColor,
	};
	const LocationDataId = EventDetailsData?.eventList[0]?.eventLocation?._id;
	const EventDataId = EventDetailsData?.eventList[0]?.event?._id;

	useEffect(() => {
		if (LocationDataId && EventDataId) {
			SetLocationList(LocationDataId);
			SetEventList(EventDataId);
		} else if (!locationList) {
			SetLocationList('');
			SetEventList('');
		}
	}, [EventDataId, LocationDataId, locationList]);

	const LocationDataList = EventPageList?.eventList?.map((item) => ({
		label: item?.eventLocation?.locationName,
		value: item?.eventLocation?._id,
	}));

	const LocationSelect = LocationDataList?.reduce((accumulator, currentItem) => {
		const isDuplicate = accumulator.some((item) => item.value === currentItem.value);
		if (!isDuplicate) {
			accumulator.push(currentItem);
		}
		return accumulator;
	}, []);

	const FilteredList = EventPageList?.eventList?.filter(
		(item) => item?.event?.eventLocationId === locationList,
	);

	const FilteredEventSelect = FilteredList?.map((item) => ({
		label: item?.event?.eventName,
		value: item?.event?._id,
	}));

	const EventListDataFiltered = EventPageList?.eventList?.filter(
		(item) => item?.event?._id === EventList && item?.eventLocation?._id === locationList,
	);

	const storedData = JSON.parse(sessionStorage.getItem('cart'));

	const initialData = storedData ? storedData : [];

	const [selectedData, setSelectedData] = useState(initialData);

	const handleSelect = (
		quantity,
		fees,
		eventIndex,
		ticketIndex,
		feesIndex,
		ticketname,
		eventName,
		ticketList,
		item,
	) => {
		const ticketType = fees?.ticketType;
		const ticketId = ticketList?._id;
		const eventId = item?.event?._id;
		const redemption = ticketList?.redemption[0]?.redemption;
		const scanlimit = ticketList?.ticketScanLimit;
		const purchaselimit = ticketList?.purchaseLimit;
		const locationId = item?.event?.eventLocationId;
		const address = item?.eventLocation?.address;
		const locationName = item?.eventLocation?.locationName;

		if (quantity === '') {
			setSelectedData((prevState) =>
				prevState.filter(
					(item) =>
						item.eventIndex !== eventIndex ||
						item.ticketIndex !== ticketIndex ||
						item.feesIndex !== feesIndex,
				),
			);
		} else {
			const selectedInfo = {
				eventIndex,
				ticketIndex,
				feesIndex,
				fees,
				quantity,
				ticketname,
				eventName,
				ticketType,
				ticketId,
				eventId,
				locationId,
				address,
				locationName,
				redemption,
				scanlimit,
				purchaselimit,
			};

			const selectedIndex = selectedData.findIndex(
				(item) =>
					item.eventIndex === eventIndex &&
					item.ticketIndex === ticketIndex &&
					item.feesIndex === feesIndex,
			);

			if (selectedIndex !== -1) {
				setSelectedData((prevState) => {
					const newArray = [...prevState];
					newArray[selectedIndex] = selectedInfo;
					return newArray;
				});
			} else {
				setSelectedData((prevState) => [...prevState, selectedInfo]);
			}
		}
	};

	const handlevalidate = () => {
		setModalShow(true);
	};

	return (
		<div className='TicketMain'>
			<Container fluid className='TicketBlur' style={applyStyles}>
				<Container className='d-flex ticketContainer flex-column'>
					<Row className='ticketDetailbanner'>
						<div className='bannerBgImageMain'>
							<img
								src={
									EventPageList?.ticketBannerImage
										? EventPageList?.ticketBannerImage
										: TicketBannerImage
								}
								alt='banner'></img>
							<div className='black'></div>
							<div className='bannerBgoverlay'>
								<Row>
									<Col>
										<h4 className='fw-bold '>GET TICKETS</h4>
									</Col>
									<div className='TicketSelect'>
										<Col lg={2} md={4} sm={12}>
											<select
												className='form-select'
												value={locationList}
												onChange={(e) => {
													SetLocationList(e.target.value);
												}}
												aria-label='Default select example'>
												<option value=''>Select Location</option>

												{LocationSelect?.map((item) => (
													<option key={item?.value} value={item?.value}>
														{item.label}
													</option>
												))}
											</select>
										</Col>
										<Col lg={2} md={4} sm={12}>
											<select
												className='form-select'
												onChange={(e) => {
													SetEventList(e.target.value);
												}}
												aria-label='Default select example'>
												<option value=''>Select Event </option>
												{FilteredEventSelect?.map((item) => (
													<option key={item?.value} value={item?.value}>
														{item.label}
													</option>
												))}
											</select>
										</Col>
									</div>
								</Row>
								{locationList !== '' && (
									<Row className='d-flex justify-content-center my-2'>
										<Col lg={2} className='ticketLocate'>
											<button
												onClick={() => {
													SetLocationList('');
												}}>
												Clear Filter
											</button>
										</Col>
									</Row>
								)}
							</div>
						</div>
					</Row>
					<Row>
						<div className='ticketLocate'>
							<h3>CAN'T FIND YOUR ONLINE TICKETS?</h3>
							<button onClick={() => setLocate(true)}>
								CLICK HERE TO LOCATE YOUR TICKET
							</button>
						</div>
					</Row>

					<Row>
						{EventPageList?.eventList?.length == null ? (
							<div className='d-flex justify-content-center'>
								<img src={NoTicket} alt='' className='NoTicket' />
							</div>
						) : null}
					</Row>
					<Row className='mt-2 mx-2 my-2 gap-10 ticketListMain' style={applyStyles}>
						{EventListDataFiltered?.length > 0
							? EventListDataFiltered?.map((item, eventIndex) => (
									<Col lg={12} className='ticketList ticketmap' key={eventIndex}>
										<Row>
											<Col lg={12} className='ticketHeading'>
												<h1>{item?.event?.eventName}</h1>
											</Col>
											<Col lg={12}>
												<Row className='mt-3 ticketLocateEvent'>
													<Col lg={7} md={12} sm={12}>
														<div className='ticketEvent'>
															<div>
																<h5>Event Location</h5>
															</div>
															<div className='d-flex  justify-content-start align-items-center'>
																<div className='px-2'>
																	<img
																		src={LocationImage}
																		alt=''
																	/>{' '}
																</div>
																<div className='px-2 text-center'>
																	<h6>
																		{
																			item?.eventLocation
																				?.locationName
																		}
																	</h6>
																</div>
																<div className='px-2 text-center'>
																	<h6>
																		{
																			item?.eventLocation
																				?.address
																		}
																	</h6>
																</div>
															</div>
														</div>
													</Col>
													<Col lg={5} md={12} sm={12}>
														<div className='ticketLocateContent'>
															<div>
																<span className='ticketLocateContentSpan'>
																	Event Date (Time-Zone :{' '}
																	{item?.timeZoneName})
																</span>
															</div>
															<div className='d-flex  justify-content-start align-items-center mb-2'>
																<div className='d-flex flex-column'>
																	{item.event?.eventSchedule?.map(
																		(
																			items,
																			eventScheduleIndex,
																		) => (
																			<div
																				className='TicketDateClassMain'
																				key={
																					eventScheduleIndex
																				}>
																				<div>
																					<img
																						src={
																							CalenderImage
																						}
																						alt=''
																					/>
																				</div>
																				<div
																					className='TicketDateClass'
																					dangerouslySetInnerHTML={{
																						__html: convertDateTime(
																							items?.eventDateAndTimeFrom,
																							items?.eventDateAndTimeTo,
																						),
																					}}>
																					{/* <div>
																						<h6 className='textblue'>
																							{timeConvert(
																								items?.eventDateAndTimeFrom,
																							)}
																						</h6>
																					</div>
																					<div>
																						<h6 className='textRed'>
																							{
																								items?.eventDateAndTimeFrom.split(
																									' ',
																								)[1]
																							}
																						</h6>
																					</div>
																					<div>
																						<h6 className='textblue'>
																							To
																						</h6>
																					</div>
																					<div>
																						<h6 className='textRed'>
																							{
																								items?.eventDateAndTimeTo.split(
																									' ',
																								)[1]
																							}
																						</h6>
																					</div> */}
																				</div>
																			</div>
																		),
																	)}
																</div>
															</div>
														</div>
													</Col>
												</Row>
											</Col>
											<hr />
											<Col lg={12} className='my-3'>
												{item?.ticket?.length > 0 ? (
													item?.ticket?.map((ticketList, ticketIndex) => (
														<>
															<Row
																className='ticketSection'
																key={ticketIndex}>
																<Col lg={7}>
																	<div>
																		<div className='ticketSectionHead'>
																			<div className='px-2'>
																				<img
																					src={
																						TicketImage
																					}
																					alt=''
																				/>{' '}
																			</div>
																			<div className='px-2 ticketHeadQuantity '>
																				<div>
																					<h2>
																						{
																							ticketList?.ticketName
																						}
																					</h2>
																				</div>

																				{ticketList?.ticketType ===
																					'limited' &&
																					+ticketList?.totalTicketQuantity <=
																						10 && (
																						<div className='ticketQuantityAvail'>
																							<span>
																								Available
																								Tickets
																								#{' '}
																								{
																									ticketList?.totalTicketQuantity
																								}
																							</span>
																						</div>
																					)}
																			</div>
																		</div>
																	</div>
																</Col>
																<Col lg={5}>
																	<div className='ticketSectionType'>
																		<div>
																			<h5 className='ticketuseDate'>
																				Ticket Use Date{' '}
																			</h5>
																		</div>
																		<div className='d-flex  justify-content-start align-items-center mb-2'>
																			<div className='d-flex flex-column'>
																				{ticketList?.redemption[0]?.redemption?.map(
																					(
																						redem,
																						indexs,
																					) => (
																						<div
																							className='TicketDateClassMain'
																							key={
																								indexs
																							}>
																							<div>
																								<img
																									src={
																										CalenderImage
																									}
																									alt=''
																								/>
																							</div>
																							<div
																								className='TicketDateClass'
																								dangerouslySetInnerHTML={{
																									__html: convertDateTime(
																										redem?.redemDateAndTimeFrom,
																										redem?.redemDateAndTimeTo,
																									),
																								}}>
																								{/* <div>
																									<h6 className='textblue'>
																									{timeConvert(
																										redem?.redemDateAndTimeFrom,
																									)}
																									</h6>
																								</div>
																								<div>
																									<h6 className='textRed'>
																									{
																										redem?.redemDateAndTimeFrom.split(
																											' ',
																										)[1]
																									}
																									</h6>
																								</div>
																								<div>
																									<h6 className='textblue'>
																										To
																									</h6>
																								</div>
																								<div>
																									<h6 className='textRed'>
																									{
																										redem?.redemDateAndTimeTo.split(
																											' ',
																										)[1]
																									}
																									</h6>
																								</div> */}
																							</div>
																						</div>
																					),
																				)}
																			</div>
																		</div>
																	</div>
																</Col>
															</Row>

															<Row className='ticketDesCol'>
																<Col lg={12} className='my-2'>
																	<Row>
																		<Col lg={6} md={12}>
																			<div className='ticketSectionType ticketSectionlast'>
																				<div>
																					<h5 className='ticketdescriptiontitle'>
																						Ticket
																						Description
																					</h5>
																				</div>
																				<div className='d-flex mt-2  justify-content-start align-items-center'>
																					<div className='px-2 ticketdecriptionpara text-justify'>
																						<p>
																							{
																								ticketList?.description
																							}
																						</p>
																					</div>
																				</div>
																				{ticketList?.announcement ===
																					'<p><br></p>' ||
																					(ticketList?.announcement !==
																						'' && (
																						<>
																							<div>
																								<h6 className='ticketHeadingAnn fw-bold'>
																									Ticket
																									Announcement
																								</h6>
																							</div>
																							<div className='d-flex mt-2  justify-content-start align-items-center'>
																								<div
																									className='px-2 text-justify'
																									dangerouslySetInnerHTML={{
																										__html: ticketList?.announcement,
																									}}></div>
																							</div>
																						</>
																					))}
																			</div>
																		</Col>
																		<Col
																			lg={4}
																			md={6}
																			className='d-flex  justify-content-start align-items-center flex-column'>
																			<Row>
																				<div className='ticketSectionType'>
																					<h5 className='ticketchoosse fw-bold'>
																						<u>
																							Choose
																							your
																							tickets
																						</u>
																					</h5>
																				</div>
																				{ticketList?.feesStructure[0]?.ticket?.map(
																					(
																						fees,
																						feesIndex,
																					) => (
																						<div
																							className='ticketnameli d-flex justify-content-between ticketSectionType'
																							key={
																								feesIndex
																							}>
																							<Col
																								lg={
																									6
																								}
																								md={
																									3
																								}
																								sm={
																									4
																								}>
																								<h5>
																									{
																										fees?.ticketType
																									}{' '}
																									:
																								</h5>
																							</Col>
																							<Col
																								lg={
																									3
																								}
																								md={
																									4
																								}
																								sm={
																									3
																								}>
																								<h5 className='fw-bold '>
																									{' '}
																									$
																									{
																										fees
																											?.ticketPrice
																											?.price
																									}
																								</h5>
																							</Col>
																							<Col
																								lg={
																									3
																								}
																								md={
																									4
																								}
																								sm={
																									3
																								}>
																								<select
																									value={
																										selectedData.find(
																											(
																												item,
																											) =>
																												item.eventIndex ===
																													eventIndex &&
																												item.ticketIndex ===
																													ticketIndex &&
																												item.feesIndex ===
																													feesIndex,
																										)
																											?.quantity ||
																										''
																									}
																									name=''
																									id=''
																									className='ticketSelect'
																									onChange={(
																										e,
																									) =>
																										handleSelect(
																											e
																												.target
																												.value,
																											fees,
																											eventIndex,
																											ticketIndex,
																											feesIndex,
																											ticketList?.ticketName,
																											item
																												?.event
																												?.eventName,
																											ticketList,
																											item,
																										)
																									}>
																									<option value=''>
																										Qty
																									</option>
																									{Array.from(
																										{
																											length: ticketList.purchaseLimit,
																										},
																										(
																											_,
																											index,
																										) =>
																											index +
																											1,
																									).map(
																										(
																											value,
																										) => (
																											<option
																												key={
																													value
																												}
																												value={
																													value
																												}>
																												{
																													value
																												}
																											</option>
																										),
																									)}
																								</select>
																							</Col>
																						</div>
																					),
																				)}
																			</Row>
																		</Col>
																		<Col
																			lg={2}
																			md={4}
																			className='ticketBtnList'>
																			<button
																				className='TicketBuyBtn '
																				onClick={() =>
																					handlevalidate()
																				}
																				disabled={
																					selectedData.length ===
																					0
																				}>
																				Buy Ticket
																			</button>
																			{/* <Button className='TicketBuyBtn btn btn-dark' onClick={() => { selectedData?.length > 0 ? setModalShow(true) : setModalShow(false) }}>Buy Ticket</Button>*/}
																		</Col>
																	</Row>
																</Col>
															</Row>

															<hr />
														</>
													))
												) : (
													<h3 className='text-center fw-bold'>
														No tickets
													</h3>
												)}
											</Col>
										</Row>
									</Col>
								))
							: EventPageList?.eventList?.map((item, eventIndex) => (
									<Col lg={12} className='ticketList ticketmap' key={eventIndex}>
										<Row>
											<Col lg={12} className='ticketHeading'>
												<h1>{item?.event?.eventName}</h1>
											</Col>
											<Col lg={12}>
												<Row className='mt-3 ticketLocateEvent'>
													<Col lg={7} md={12} sm={12}>
														<div className='ticketEvent'>
															<div>
																<h5>Event Location</h5>
															</div>
															<div className='d-flex  justify-content-start align-items-center flex-wrap'>
																<div className='px-2'>
																	<img
																		src={LocationImage}
																		alt=''
																	/>{' '}
																</div>
																<div className='px-2 text-center'>
																	<h6>
																		{
																			item?.eventLocation
																				?.locationName
																		}
																	</h6>
																</div>
																<div className='px-2 text-center'>
																	<h6>
																		{
																			item?.eventLocation
																				?.address
																		}
																	</h6>
																</div>
															</div>
														</div>
													</Col>
													<Col lg={5} md={12} sm={12}>
														<div className='ticketLocateContent'>
															<div>
																<span className='ticketLocateContentSpan'>
																	Event Date (Time-Zone :{' '}
																	{item?.timeZoneName})
																</span>
															</div>
															<div className='d-flex  justify-content-start align-items-center mb-2'>
																<div className='d-flex flex-column'>
																	{item.event?.eventSchedule?.map(
																		(
																			items,
																			eventScheduleIndex,
																		) => (
																			<div
																				className='TicketDateClassMain'
																				key={
																					eventScheduleIndex
																				}>
																				<div>
																					<img
																						src={
																							CalenderImage
																						}
																						alt=''
																					/>
																				</div>
																				<div
																					className='TicketDateClass'
																					dangerouslySetInnerHTML={{
																						__html: convertDateTime(
																							items?.eventDateAndTimeFrom,
																							items?.eventDateAndTimeTo,
																						),
																					}}></div>
																			</div>
																		),
																	)}
																</div>
															</div>
														</div>
													</Col>
													<hr />
												</Row>
											</Col>

											<Col lg={12} className='my-3'>
												{item?.ticket?.length > 0 ? (
													item?.ticket?.map((ticketList, ticketIndex) => (
														<div key={ticketIndex}>
															<Row className='ticketSection'>
																<Col lg={7}>
																	<div>
																		<div className='ticketSectionHead'>
																			<div className='px-2'>
																				<img
																					src={
																						TicketImage
																					}
																					alt=''
																				/>{' '}
																			</div>
																			<div className='px-2 ticketHeadQuantity '>
																				<div>
																					<h2>
																						{
																							ticketList?.ticketName
																						}
																					</h2>
																				</div>

																				{ticketList?.ticketType ===
																					'limited' &&
																					+ticketList?.totalTicketQuantity <=
																						10 && (
																						<div className='ticketQuantityAvail'>
																							<span>
																								Available
																								Tickets
																								#{' '}
																								{
																									ticketList?.totalTicketQuantity
																								}
																							</span>
																						</div>
																					)}
																			</div>
																		</div>
																	</div>
																</Col>
																<Col lg={5}>
																	<div className='ticketSectionType'>
																		<div>
																			<h5 className='ticketuseDate'>
																				Ticket Use Date{' '}
																			</h5>
																		</div>
																		<div className='d-flex  justify-content-start align-items-center mb-2'>
																			<div className='d-flex flex-column'>
																				{ticketList?.redemption[0]?.redemption?.map(
																					(
																						redem,
																						indexs,
																					) => (
																						<div
																							className='TicketDateClassMain'
																							key={
																								indexs
																							}>
																							<div>
																								<img
																									src={
																										CalenderImage
																									}
																									alt=''
																								/>
																							</div>
																							<div
																								className='TicketDateClass'
																								dangerouslySetInnerHTML={{
																									__html: convertDateTime(
																										redem?.redemDateAndTimeFrom,
																										redem?.redemDateAndTimeTo,
																									),
																								}}>
																								{/* <div>
																									<h6 className='textblue'>
																										{timeConvert(
																											redem?.redemDateAndTimeFrom,
																										)}
																									</h6>
																								</div>
																								<div>
																									<h6 className='textRed'>
																										{
																											redem?.redemDateAndTimeFrom.split(
																												' ',
																											)[1]
																										}
																									</h6>
																								</div>
																								<div>
																									<h6 className='textblue'>
																										To
																									</h6>
																								</div>
																								<div>
																									<h6 className='textRed'>
																										{
																											redem?.redemDateAndTimeTo.split(
																												' ',
																											)[1]
																										}
																									</h6>
																								</div> */}
																							</div>
																						</div>
																					),
																				)}
																			</div>
																		</div>
																	</div>
																</Col>
															</Row>

															<Row className='ticketDesCol'>
																<Col lg={12} className='my-2'>
																	<Row>
																		<Col lg={6} md={12}>
																			<div className='ticketSectionType ticketSectionlast'>
																				<div>
																					<h5 className='ticketdescriptiontitle'>
																						Ticket
																						Description
																					</h5>
																				</div>
																				<div className='d-flex mt-2  justify-content-start align-items-center'>
																					<div className='px-2 ticketdecriptionpara text-justify'>
																						<p>
																							{
																								ticketList?.description
																							}
																						</p>
																					</div>
																				</div>
																				{ticketList?.announcement ===
																					'<p><br></p>' ||
																					(ticketList?.announcement !==
																						'' && (
																						<>
																							<div>
																								<h6 className='ticketHeadingAnn fw-bold'>
																									Ticket
																									Announcement
																								</h6>
																							</div>
																							<div className='d-flex mt-2  justify-content-start align-items-center'>
																								<div
																									className='px-2 text-justify'
																									dangerouslySetInnerHTML={{
																										__html: ticketList?.announcement,
																									}}></div>
																							</div>
																						</>
																					))}
																			</div>
																		</Col>
																		<Col
																			lg={4}
																			md={6}
																			className='d-flex  justify-content-start align-items-center flex-column'>
																			<Row>
																				<div className='ticketSectionType'>
																					<h5 className=' ticketchoosse ticketchoosse fw-bold'>
																						<u>
																							Choose
																							your
																							tickets
																						</u>
																					</h5>
																				</div>
																				{ticketList?.feesStructure[0]?.ticket?.map(
																					(
																						fees,
																						feesIndex,
																					) => (
																						<div
																							className='ticketnameli d-flex justify-content-between ticketSectionType'
																							key={
																								feesIndex
																							}>
																							<Col
																								lg={
																									6
																								}
																								md={
																									3
																								}
																								sm={
																									4
																								}>
																								<h5>
																									{
																										fees?.ticketType
																									}{' '}
																									:
																								</h5>
																							</Col>
																							<Col
																								lg={
																									3
																								}
																								md={
																									4
																								}
																								sm={
																									3
																								}>
																								<h5 className='fw-bold '>
																									{' '}
																									$
																									{
																										fees
																											?.ticketPrice
																											?.price
																									}
																								</h5>
																							</Col>
																							<Col
																								lg={
																									3
																								}
																								md={
																									4
																								}
																								sm={
																									3
																								}>
																								<select
																									value={
																										selectedData.find(
																											(
																												item,
																											) =>
																												item.eventIndex ===
																													eventIndex &&
																												item.ticketIndex ===
																													ticketIndex &&
																												item.feesIndex ===
																													feesIndex,
																										)
																											?.quantity ||
																										''
																									}
																									name=''
																									id=''
																									className='ticketSelect'
																									onChange={(
																										e,
																									) =>
																										handleSelect(
																											e
																												.target
																												.value,
																											fees,
																											eventIndex,
																											ticketIndex,
																											feesIndex,
																											ticketList?.ticketName,
																											item
																												?.event
																												?.eventName,
																											ticketList,
																											item,
																										)
																									}>
																									<option value=''>
																										Qty
																									</option>
																									{Array.from(
																										{
																											length: ticketList.purchaseLimit,
																										},
																										(
																											_,
																											index,
																										) =>
																											index +
																											1,
																									).map(
																										(
																											value,
																										) => (
																											<option
																												key={
																													value
																												}
																												value={
																													value
																												}>
																												{
																													value
																												}
																											</option>
																										),
																									)}
																								</select>
																							</Col>
																						</div>
																					),
																				)}
																			</Row>
																		</Col>
																		<Col
																			lg={2}
																			md={4}
																			className='ticketBtnList'>
																			<button
																				className='TicketBuyBtn'
																				onClick={() =>
																					handlevalidate()
																				}
																				disabled={
																					selectedData.length ===
																					0
																				}>
																				Buy Ticket
																			</button>
																		</Col>
																	</Row>
																</Col>
															</Row>

															<hr />
														</div>
													))
												) : (
													<h3 className='text-center fw-bold'>
														No tickets
													</h3>
												)}
											</Col>
										</Row>
									</Col>
								))}
						{
							<MydModalWithGrid
								show={modalShow}
								item={selectedData}
								onHide={setModalShow}
							/>
						}
						{<LocateTicket show={Locate} onHide={setLocate}></LocateTicket>}
					</Row>
				</Container>
			</Container>
		</div>
	);
};

export default Ticket;

import { useFormik } from 'formik';
import {
	VendorPost,
	VendorData,
	errorMessage,
	loadingStatus,
	successMessage,
	ListEventNameList,
} from '../Redux/Slice';
import '../Styles/Common.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from 'react';
import { FormGroup, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import Spinners from '../component/Spinners';
import Swal from 'sweetalert2';
import { errTitle, scc, poscent, errIcon, sccIcon, BtnCanCel, BtnGreat } from '../Constant';

const Vendor = () => {
	const { EventNameList, VendorAdminData, success, error } = useSelector(
		(state) => state.festivWebsite,
	);
	const [isLoading, setIsLoading] = useState(false);
	const [Eventname, SetEventname] = useState([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(ListEventNameList());
		dispatch(VendorData());
	}, [dispatch]);

	const EventList = EventNameList?.map((item) => ({
		label: item?.eventName,
		value: item?._id,
	}));

	const applyStyles = {
		fontFamily: VendorAdminData?.fontFamily,
		color: VendorAdminData?.fontColor,
	};

	const Notification = useCallback(
		(val, tit, pos, ico, btn) => {
			Swal.fire({
				position: `${pos}`,
				title: `${tit}`,
				text: `${val}`,
				icon: `${ico}`,
				confirmButtonText: `${btn}`,
			}).then(function () {
				navigate('/');
				dispatch(errorMessage({ errors: '' }));
				dispatch(successMessage({ successess: '' }));
				dispatch(loadingStatus({ loadingStatus: false }));
			});
		},
		[navigate, dispatch],
	);

	useEffect(() => {
		success && Notification(success, scc, poscent, sccIcon, BtnGreat);
		if (error !== 'something went wrong' || error !== 'Please try again') {
			error && Notification(error, errTitle, poscent, errIcon, BtnCanCel);
		}
	}, [error, success, Notification]);

	const HandleSelect = (e) => {
		SetEventname(Array.isArray(e) ? e.map((x) => x.value) : []);
		formik.setFieldValue('event', Eventname);
	};

	const formik = useFormik({
		initialValues: {
			firstname: '',
			lastName: '',
			phoneNumber: '',
			email: '',
			organizationName: '',
			BussinessAddress: '',
			event: '',
			city: '',
			zipcode: '',
			message: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.firstname) {
				errors.firstname = 'Required*';
			}
			if (!values.lastName) {
				errors.lastName = 'Required*';
			}
			if (!values.phoneNumber) {
				errors.phoneNumber = 'Required*';
			} else if (!/^\d{10}$/.test(values.phoneNumber)) {
				errors.phoneNumber = 'Must be exactly 10 digits';
			}

			if (!values.email) {
				errors.email = 'Required*';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				errors.email = 'Must be valid e-mail';
			}

			if (!values.organizationName) {
				errors.organizationName = 'Required*';
			}
			if (!values.BussinessAddress) {
				errors.BussinessAddress = 'Required*';
			}
			// if (!values.event) {
			//     errors.event = 'Required*';
			// }
			if (!values.city) {
				errors.city = 'Required*';
			}
			if (!values.zipcode) {
				errors.zipcode = 'Required*';
			} else if (!/^\d{5}$/.test(values.zipcode)) {
				errors.zipcode = 'Must be exactly 5 digits';
			}

			if (!values.message) {
				errors.message = 'Required*';
			} else if (values.message.length > 2000) {
				errors.message = 'Must be 2000 characters or less';
			}

			if (Object.keys(errors).length === 0) {
				formik.setStatus({ isSubmitting: true });
			}

			return errors;
		},
		onSubmit: (values, { setSubmitting, resetForm }) => {
			values.event = Eventname;
			dispatch(VendorPost(values));
			setIsLoading(true);
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
			setTimeout(() => {
				setSubmitting(false);
			}, 2000);
			resetForm();
		},
	});

	return (
		(VendorAdminData === '' && <Spinners />) || (
			<div className='bannerBackground'>
				<div className='container-fluid BackGroundBlur' style={applyStyles}>
					<div className='container bannerMain  mt-5'>
						<div className='row ticketDetailbanner'>
							<div className='bannerImg'>
								<img src={VendorAdminData?.vendorBannerImage} alt='' />
								
							</div>
						</div>

						<div className='row d-flex justify-content-center align-items-center py-3 px-3'>
							<div
								className='col-lg-9 vendorDescription'
								dangerouslySetInnerHTML={{
									__html: VendorAdminData?.description,
								}}></div>
						</div>
						<div className='row d-flex justify-content-center py-3 px-3'>
							<div className='col-lg-9 col-md-11 col-sm-10 formBackground '>
								<div className='row text-center pt-5'>
									<h3 className='Orange'>Become a Vendor</h3>
								</div>

								<form onSubmit={formik.handleSubmit}>
									<div className='row d-flex justify-content-center formInput'>
										<div className='col-lg-12 col-md-12 col-sm-12'>
											<div className='row'>
												<div className='col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='firstname'>
														<input
															placeholder='Enter Your First Name*'
															onChange={formik.handleChange}
															value={formik.values.firstname}
															name='firstname'
														/>
														<p className='text-danger mb-0 mx-2'>
															{formik.errors.firstname}
														</p>
													</FormGroup>
												</div>
												<div className='col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='lastName'>
														<input
															placeholder='Enter Your Last Name*'
															onChange={formik.handleChange}
															value={formik.values.lastName}
															name='lastName'
														/>
														<p className='text-danger mb-0 mx-2'>
															{formik.errors.lastName}
														</p>
													</FormGroup>
												</div>
											</div>
										</div>

										<div className='col-lg-12 col-md-12 col-sm-12'>
											<div className='row'>
												<div className='col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='phoneNumber'>
														<input
															placeholder='Enter Your Phone Number*'
															onChange={formik.handleChange}
															value={formik.values.phoneNumber}
															name='phoneNumber'
														/>
														<p className='text-danger mb-0 mx-2'>
															{formik.errors.phoneNumber}
														</p>
													</FormGroup>
												</div>
												<div className='col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='email'>
														<input
															placeholder='Enter Your Email*'
															onChange={formik.handleChange}
															value={formik.values.email}
															name='email'
														/>
														<p className='text-danger mb-0 mx-2'>
															{formik.errors.email}
														</p>
													</FormGroup>
												</div>
											</div>
										</div>

										<div className='col-lg-12 col-md-12 col-sm-12'>
											<div className='row'>
												<div className='col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='organizationName'>
														<input
															placeholder='Enter Your Organization Name*'
															onChange={formik.handleChange}
															value={formik.values.organizationName}
															name='organizationName'
														/>
														<p className='text-danger mb-0 mx-2'>
															{formik.errors.organizationName}
														</p>
													</FormGroup>
												</div>
												<div className='col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='BussinessAddress'>
														<input
															placeholder='Enter Your Busniess Address*'
															onChange={formik.handleChange}
															value={formik.values.BussinessAddress}
															name='BussinessAddress'
														/>
														<p className='text-danger mb-0 mx-2'>
															{formik.errors.BussinessAddress}
														</p>
													</FormGroup>
												</div>
											</div>
										</div>
										<div className='col-lg-12 col-md-12 col-sm-12 mt-1'>
											<div className='row d-flex justify-content-center align-items-center'>
												<Select
													options={EventList}
													value={EventList?.filter((obj) =>
														Eventname.includes(obj.value),
													)}
													// value={formik.values.event}
													className='sponsorSelect'
													placeholder='Select Your Events*'
													onChange={(e) => HandleSelect(e)}
													isMulti
													isClearable
													name='event'
													id='event'
												/>
												<p className='text-danger mb-0 mx-2'>
													{formik.errors.event}
												</p>
											</div>
										</div>
										<div className='col-lg-12 col-md-12 col-sm-12 mt-1'>
											<div className='row'>
												<div className='col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='city'>
														<input
															placeholder='Enter Your City Name*'
															onChange={formik.handleChange}
															value={formik.values.city}
															name='city'
														/>
														<p className='text-danger mb-0 mx-2'>
															{formik.errors.city}
														</p>
													</FormGroup>
												</div>
												<div className='col-lg-6 col-md-6 col-sm-12'>
													<FormGroup id='zipcode'>
														<input
															placeholder='Enter Your Zipcode*'
															onChange={formik.handleChange}
															value={formik.values.zipcode}
															name='zipcode'
														/>
														<p className='text-danger mb-0 mx-2'>
															{formik.errors.zipcode}
														</p>
													</FormGroup>
												</div>
											</div>
										</div>
										<div className='col-lg-12 col-md-12 col-sm-12 mt-1'>
											<div className='row'>
												<div className='col-lg-12 col-md-12 col-sm-12'>
													<FormGroup id='message'>
														<textarea
															placeholder='Type your Message*'
															onChange={formik.handleChange}
															value={formik.values.message}
															name='message'
														/>
														<p className='text-danger mb-0 mx-2'>
															{formik.errors.message}
														</p>
													</FormGroup>
												</div>
											</div>
										</div>

										<div className='row mt-2 d-flex justify-content-center align-items-center text-center pb-3'>
											<div className='col-lg-3 d-flex justify-content-center align-items-center'>
												<button
													type='submit'
													onClick={formik.handleSubmit}
													name='submit'
													className='submitBtn text-center'>
													{isLoading ? <Spinner /> : 'Submit'}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};
export default Vendor;

import React from 'react';
import { Row } from 'react-bootstrap';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

const SliderScroll = ({ item }) => {
	let slidesToShow = 1;
	let slidesToScroll = 1;

	if (item?.sponsorImages.length >= 1 && item?.sponsorImages.length <= 2) {
		slidesToShow = 1;
		slidesToScroll = 1;
	} else if (item?.sponsorImages.length > 2 && item?.sponsorImages.length <= 4) {
		slidesToShow = 2;
		slidesToScroll = 2;
	} else if (item?.sponsorImages.length > 3 && item?.sponsorImages.length <= 6) {
		slidesToShow = 3;
		slidesToScroll = 2;
	} else if (item?.sponsorImages.length > 6 && item?.sponsorImages.length <= 10) {
		slidesToShow = 4;
		slidesToScroll = 2;
	}

	var settings = {
		dots: false,
		infinite: true,
		slidesToShow: slidesToShow,
		slidesToScroll: slidesToScroll,
		autoplay: true,
		speed: 5000,
		autoplaySpeed: 1000,
		cssEase: 'linear',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: slidesToShow,
					slidesToScroll: slidesToScroll,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 900,
				settings: {
					slidesToShow: slidesToShow,
					slidesToScroll: slidesToScroll,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: slidesToShow,
					slidesToScroll: slidesToScroll,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: slidesToShow,
					slidesToScroll: slidesToScroll,
				},
			},
		],
	};

	return (
		item?.sponsorImages?.length > 0 && (
			<div className='container mt-3 mb-3 pt-2 pb-2 sponsorMain'>
				<h2 className='pb-3 text-center'>Thanks To Our Sponsors</h2>
				<Row>
					<div className='row scrollRow'>
						<div className='col-lg-10'>
							<div className='imgSlider'>
								{item?.sponsorImages.length > 0 && (
									// eslint-disable-next-line react/jsx-props-no-spreading
									<Slider {...settings}>
										{item?.sponsorImages.length > 0 &&
											item?.sponsorImages?.map((item, index) => (
												<div key={index}>
													<img src={item} alt='' />
												</div>
											))}
									</Slider>
								)}
							</div>
						</div>
					</div>
				</Row>
			</div>
		)
	);
};

export default SliderScroll;

SliderScroll.propTypes = {
	item: PropTypes.object.isRequired,
};

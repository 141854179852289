import React from 'react';
import '../Styles/Common.css';
import { useSelector } from 'react-redux';

const PrivacyPolicy = () => {
	const { HomePageList } = useSelector((state) => state.festivWebsite);

	const applyStyles = {
		fontFamily: HomePageList?.fontFamily,
		color: HomePageList?.fontColor,
	};
	return (
		<div className='bannerBackground'>
			<div className='container-fluid BackGroundBlur' style={applyStyles}>
				<div className='container bannerMain mt-5'>
					<div className='row d-flex justify-content-center align-items-center py-3 px-3'>
						<h2 className='text-center fw-bold'>Tickets Policy, Privacy, Cookies , Other Terms  &amp; Conditions</h2>
					</div>
					<div className='row d-flex justify-content-center align-items-center py-3 px-3'>
						<h2>TICKETS POLICY</h2>
						<p>
							The online tickets purchased on{' '}
							<a
								href='https://www.northbrunswickyouthsportsfestival'
								target='_blank'
								rel='noreferrer'>
								https://www.northbrunswickyouthsportsfestival
							</a>{' '}
							are NON- REFUNDABLE. In addition to the ticket price, there will be
							service fees charged based on the total tickets purchased and the prices
							of these tickets. The Service fees charged are NON- Refundable. The
							North Brunswick Youth Festival maintains a strict policy that all ticket
							sales are final. Once a purchase is made, there will be no refunds or
							exchanges granted, meaning that once a ticket is bought, there is no
							option for reimbursement, regardless of the circumstances.
						</p>
						<h2>PRIVACY, TERMS &amp; COOKIES</h2>
						<p>
							The <strong>North Brunswick Youth Sports Festival</strong> is committed
							to respecting and protecting your privacy rights when visiting any page
							of our website, https://www.northbrunswickyouthsportsfestival.com/. To
							protect your Personal Information, the
							<strong>North Brunswick Youth Sports Festival</strong> has created the
							guidelines outlined below, which explain the kinds of information the
							website collects, how it is used, the conditions under which it is made
							available to third parties, how information is maintained and our
							compliance with the Children’s Online Privacy Act. Your use of the
							website indicates your acceptance of and agreement with the terms and
							conditions of this Privacy Policy.
						</p>
						<h2>INFORMATION COLLECTED</h2>
						<p>
							While visiting the website, we may ask you to provide information that
							personally identifies you (&quot;personal information&quot;) for
							purposes of interacting with the website. Such personal information may
							include, but is not limited to, your name, phone number, street address
							and e-mail address, age and gender. On any page that collects personal
							information, we will specifically describe what information is required
							in order to provide you with the product or service or enter you in the
							promotion you have requested, as well as respond to your inquiry or
							comment. This personally identifiable data is collected by means of form
							interfaces on our website, into which the user must voluntarily enter
							the information requested. If you place an online order or enter a
							commercial transaction at this website, we will collect additional
							personally identifiable information, including billing address and
							credit card information. We may also collect personally identifiable
							information through purchase forms, surveys, contests, emails we receive
							from you and your other interactions with our website. You may browse
							the website without registering or providing any personally identifying
							information.
						</p>
						<h2>IP ADDRESSES</h2>
						<p>
							This website also collects anonymous data that is not indicative of and
							cannot be traced to an individual without the cooperation of their
							Internet Service Provider. This data includes information about the
							user&#39;s remote IP address, referral data (which page
							&quot;linked&quot; to the present request) and the browser
							software&#39;s user-agent string. This information is accepted passively
							from the HTTP headers that are sent from the user when requesting any
							file from the server.
							<strong>North Brunswick Youth Sports Festival</strong> does log IP
							addresses for systems administration, troubleshooting purposes and to
							determine website usage.
						</p>
						<h2>COOKIES</h2>
						<p>
							The website uses &quot;cookies&quot;, which are elements of data that a
							website can send to your browser, which may then be stored on your
							system. This element of data is a piece of text, not a program. The
							website can only access the information from a cookie sent by the
							website. We cannot access other cookies sent by other websites or the
							information contained therein. Additionally, we cannot learn your e-mail
							address or any other information about you through the use of a cookie.
							The only way we would learn such information is if you specifically and
							voluntarily submit that information to us, for example, through a
							registration system, by entering a sweepstakes or promotion, etc.
						</p>
						<h2>WHY DOES THE SITE USE COOKIES?</h2>
						<p>
							<strong>North Brunswick Youth Sports Festival</strong> uses cookies to
							track usage of the website and further personalize your experience when
							you are visiting the Website. By tracking usage, we can best determine
							what features of the website best serve the users. Overall, the use of
							cookies helps to give you a more personalized experience at the website.
							The information is used to keep our website fresh and relevant to you,
							the user. Cookies also allow you to avoid retyping your e-mail address
							on the website. The information stored in a cookie is used to allow us
							to personalize your experience on the website.
						</p>
						<h2>MAY I DECLINE TO ACCEPT A COOKIE?</h2>
						<p>
							You may decline to accept cookies sent by the website by selecting an
							option on your browser to reject cookies. However, some portions of the
							website may require cookies in order to provide you with the full set of
							features offered. Cookies enable our server to know that you are a
							registered user. In addition, our registration system may require that
							you accept the cookies from the website in order to access that portion
							of the website.
						</p>
						<h2>USE OF PERSONAL INFORMATION</h2>
						<p>
							We believe in protecting your privacy and will not share personal
							information with third parties outside of{' '}
							<strong>North Brunswick Youth Sports Festival</strong>, except as
							described in this Privacy Policy. We believe that we can enhance your
							experience on our website with the information you share with us. We use
							personal information about you to improve our advertising and marketing
							efforts, to statistically analyze website usage, to improve our content
							and product offerings and to personalize the website&#39;s content,
							layout, and services. We also may use your personal information, such as
							your e-mail address or mailing address, to contact you regarding
							products and/or services that may be of interest to you, to respond to
							an inquiry from you regarding the information that we have collected, to
							respond to your comments and suggestions and to fill orders and complete
							commercial transactions that you initiate with this website.
						</p>
						<p>
							<strong>North Brunswick Youth Sports Festival</strong> may match
							specific and/or aggregated personal information with third party data to
							provide you with product and content offerings that interest you and
							improve our advertising and marketing efforts. North Brunswick Youth
							Sports Festival also may disclose aggregated user statistics in order to
							describe our services to potential advertisers, partners, other third
							parties, our affiliate companies and for other lawful purposes. For
							example, we provide our advertisers with aggregate information about our
							users, such as statistics regarding the number of website users that
							view their advertisements.
						</p>
						<p>
							As part of your experience on our website, you may receive
							communications from us related to products and services that might
							interest you. While we believe these services will enhance your
							experience at our website, you will at all times be able to opt out from
							receiving these communications, either by specifically opting out of
							these services or by deleting your profile from our database.
						</p>
						<p>
							We have today, and will in the future, have arrangements with third
							party service providers, such as credit card processors, shipping
							companies, and fulfillment services, other third-party vendors, or any
							partner who assists in the operations and/or administration of this
							website.
						</p>
						<p>
							When you sign up for a service we offer on the Website, we may be
							required to share personal information with that service provider in
							order to provide the service. These third-party providers are not
							permitted to retain or use personal information for any purpose
							unrelated to providing the service.
						</p>
						<p>
							We reserve the right to disclose personal information when needed to
							comply with the law or a legal process, cooperate with investigations of
							purported unlawful activities, to identify persons violating the law, to
							investigate or assist in investigations incident to misuse or abuse of
							this website, or in connection with the sale of part or all of{' '}
							<strong>North Brunswick Youth Sports Festival</strong>.
						</p>
						<h2>Party Collecting the Information</h2>
						<p>
							Information entered by website visitors is retrieved directly by the
							North Brunswick Youth Sports Festival:
						</p>

						<p className='fw-bold'>1210 Cozzens Lane</p>
						<p className='fw-bold'>North Brunswick, NJ 08902</p>

						<p>
							This Privacy Policy only addresses the use and dissemination of
							information that the website collects from you. To the extent that you
							disclose any information to other parties, whether through, for example,
							online auctions, chat rooms, personal ads, or the like, through third
							party services or promotions offered on or through the website or
							through other websites throughout the internet, different policies may
							apply and <strong>North Brunswick Youth Sports Festival</strong> shall
							not be responsible for the use or dissemination of such information.
						</p>
						<h2>Links to Other Materials</h2>
						<p>
							Third party sites linked to and from the site are not necessarily under
							the control of North Brunswick Youth Sports Festival. {' '}
							<strong>North Brunswick Youth Sports Festival</strong> shall have no
							responsibilities or liabilities whatsoever for the content or privacy
							practices of any such linked site or any link or linking program at any
							time. <strong>North Brunswick Youth Sports Festival</strong> does not
							necessarily endorse companies (or related products or services) to or
							from which the website is linked. If you decide to access any of the
							third party sites linked to the website, you do so entirely at your own
							risk. <strong>North Brunswick Youth Sports Festival</strong> hereby
							disclaims any rights to trademarks, service marks, trade names, logos,
							copyrights, patents, domain names or other intellectual property
							interests of third parties.
						</p>

						<h2>CHILDREN&#39;S PRIVACY</h2>
						<p>
							The Children&#39;s Online Privacy Protection Act of 1998 (“COPPA”)
							applies only to websites of a commercial nature, and while{' '}
							<strong>North Brunswick Youth Sports Festival</strong> is a nonprofit
							organization, it considers COPPA to provide sound advice for any website
							that is intended for use by children. For this reason,{' '}
							<strong>North Brunswick Youth Sports Festival</strong> does not
							knowingly collect or solicit any personally identifiable information
							from children under 13 years of age without verifiable parental consent.
						</p>
						<p>
							In the event that we receive actual knowledge that we have collected
							such personal information without the requisite and verifiable parental
							consent, we will delete that information from our database as quickly as
							is practicable. With respect to any form interface that appears on a
							webpage at our website, no information will be knowingly collected from
							any user under the age of 13. Where “age” or “date of birth” is
							requested on the form interface, no information will be stored or
							transmitted unless the response indicates that the user is 13 years of
							age or older.
						</p>
						<p>
							Providing personal information is never a prerequisite for accessing the
							basic content or resources on this website.
						</p>
						<h2>MAINTENANCE OF PERSONAL INFORMATION</h2>
						<p>
							The personally identifiable information collected at this website may be
							indefinitely retained and electronically archived. Please keep in mind
							that information collected at this website may be different than that
							collected at or through other activities, events, or functions related
							to North Brunswick Youth Sports Festival and the use and disclosure, if
							any, of such information may vary and are not covered under this policy.
						</p>
						<h2>OPTING-OUT</h2>
						<p>
							We respect your privacy and ability to choose whether to share
							information with us and any third party. You may choose to
							&quot;opt-out&quot; of sharing personal information in several ways.
							When choosing to use certain features or services on the website, you
							can choose whether to share optional personal information in connection
							with the feature or service. If you receive communications from us that
							you prefer not to receive, you may opt-out from receiving these
							communications, either by specifically indicating this preference in a
							communication to us via the Unsubscribe link or by deleting your
							registration profile from our database. You may also opt-out altogether
							from providing personal information to us by not registering on the
							website and thereby not providing personal information. You may also
							choose at a later date to have your name removed from our database. To
							do so, please follow the process described below. Certain portions of
							the website and certain services provided on the website, however, do
							require registration in order to access or use these areas and services.
							If you choose not to register or provide certain information, these
							areas will not be accessible to you.
						</p>
						<h2>REVIEWING, CHANGING AND DELETING YOUR PERSONAL INFORMATION</h2>
						<p>
							You may access all of the personal information that we collect from you
							online and maintain by e-mailing us at nbysf1@gmail.com. To protect your
							privacy and security, we will also take reasonable steps to verify your
							identity before granting access or making corrections.
						</p>
						<h2>SECURITY</h2>
						<p>
							<strong>North Brunswick Youth Sports Festival</strong> maintains
							industry standard encryption technologies to ensure the security,
							integrity and privacy of the personal information that you provide
							through the website. Please keep in mind, however, that no data
							transmission over the Internet or any wireless network can be guaranteed
							to be 100% secure. As a result, we cannot guarantee the security of any
							information you transmit using our website, and therefore, it is
							important to remember that you transmit such information at your own
							risk. North Brunswick Youth Sports Festival does not assume
							responsibility for use or disclosure of your information that are the
							result of unauthorized or illegal access to our systems or to those of
							our vendors or partners.
						</p>
						<h2>CONTACTING US</h2>
						<p>
							If you have any questions or comments about our Privacy Policy, please
							e-mail us at nbysf1@gmail.com.
						</p>
						<p></p>
						<h2>EFFECTIVE DATE AND CHANGES</h2>
						<p>
							Understanding that Internet technologies are rapidly evolving, any
							changes to this policy will be promptly posted on this page, with an
							updated &quot;effective date&quot; posted below. North Brunswick Youth
							Sports Festival reserves the right to modify the terms of this policy at
							any time and in our sole discretion, by posting a change notice to this
							page. Your continued use of the website following our posting of a
							change notice will constitute binding acceptance of those changes.
						</p>
						<p>This Privacy Policy was last modified on 04/01/2024.</p>

						<p>US-1, Milltown, NJ 08850</p>
						<p>(David B. Crabiel Park)</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrivacyPolicy;

import React from 'react';
import AnimationCard from './Animation_Card';
import FestivalHighlights from './Festival_Highlights';
import Homebanner from './Home_banner';
import Promotion from './Promotion';
import SeoWidget from './Seo_Widget';
import SliderScroll from './SliderScroll';
import Spinner from '../component/Spinners';
import PropTypes from 'prop-types';

const Home = ({ items }) => {
	const applyStyles = {
		fontFamily: items?.fontFamily,
		color: items?.fontColor,
	};

	return (
		(items === '' && <Spinner />) || (
			<div style={applyStyles}>
				<Homebanner item={items} />
				<FestivalHighlights item={items} />
				<SeoWidget item={items} />
				<AnimationCard item={items} />
				<SliderScroll item={items} />
				<Promotion item={items} />
			</div>
		)
	);
};

export default Home;
Home.propTypes = {
	items: PropTypes.object.isRequired,
};

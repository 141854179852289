import React, { useEffect } from 'react';
import { EventPageData, FestivalHoursData } from '../Redux/Slice';
import '../Styles/Common.css';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import Spinner from '../component/Spinners';

const FestivalHours = () => {
	const { FestivalHoursAdminData, EventPageList } = useSelector((state) => state.festivWebsite);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(FestivalHoursData());
		dispatch(EventPageData());
	}, [dispatch]);

	const applyStyles = {
		fontFamily: FestivalHoursAdminData?.fontFamily,
		color: FestivalHoursAdminData?.fontColor,
	};
	function timeConvert(val) {
		const DateConvers = new Date(val);
		return DateConvers.toDateString();
	}

	return (
		(FestivalHoursAdminData === '' && <Spinner />) || (
			<div className='bannerBackground'>
				<div className='container-fluid BackGroundBlur' style={applyStyles}>
					<div className='container bannerMain flexCenter mt-5'>
						<div className='row ticketDetailbanner'>
							<div className='bannerImg'>
								<img
									src={FestivalHoursAdminData?.festivalHoursBannerImage}
									alt=''
								/>
							</div>
						</div>
						<div className='row d-flex justify-content-center align-items-center py-3 px-3'>
							<h2 className='text-center fw-bold festivalhourTitle'>
								<u>Festival Hours</u>
							</h2>
						</div>
						<div className='row d-flex justify-content-center align-items-center py-3 px-3'>
							<div
								className='col-lg-9 festivalhourDescription'
								dangerouslySetInnerHTML={{
									__html: FestivalHoursAdminData?.description,
								}}></div>
						</div>
						{/* <div className='row d-flex justify-content-center align-items-center text-center py-3 px-3'>
							<h3 className='col-lg-12 fw-bold festivalhourTitle'>
								<u>Live Events</u>
							</h3>
							{EventPageList?.eventList?.length > 0 ? (
								EventPageList?.eventList?.map((item, index) => (
									<div className='row EventLive' key={index}>
										<div className='col-lg-12'>
											<h5 className=''>{item?.event?.eventName}:</h5>
										</div>
										<div className='col-lg-12 d-flex flex-column my-2 align-items-center'>
											{item?.event?.eventSchedule?.map((items, inx) => (
												<div className='eventListDate' key={inx}>
													<div>
														<h6 className='textblue'>
															{timeConvert(
																items?.eventDateAndTimeFrom,
															)}
														</h6>
													</div>
													<div>
														<h6 className='textRed'>
															{
																items?.eventDateAndTimeFrom.split(
																	' ',
																)[1]
															}
														</h6>
													</div>
													<div>
														<h6 className='textblue'>To</h6>
													</div>
													<div>
														<h6 className='textRed'>
															{
																items?.eventDateAndTimeTo.split(
																	' ',
																)[1]
															}
														</h6>
													</div>
												</div>
											))}
										</div>
									</div>
								))
							) : (
								<h4 className='fw-bold border my-3 '>No Live Events</h4>
							)}
						</div> */}
					</div>
				</div>
			</div>
		)
	);
};

export default FestivalHours;

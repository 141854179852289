import React from 'react';
import { Carousel } from 'react-bootstrap';
import Marquee from 'react-fast-marquee';
import PropTypes from 'prop-types';

const Home_banner = ({ item }) => {
	const applyStyles = {
		fontFamily: item?.fontFamily,
		color: item?.fontColor,
	};

	return (
		<>
			<section className=' Home-banner'>
				<Carousel slide={false} fade>
					<Carousel.Item interval={3000}>
						<img
							className='Carousel-items-set'
							src={item?.bannerImage1}
							alt='First slide'
						/>
					</Carousel.Item>
					<Carousel.Item interval={3000}>
						<img
							className=' Carousel-items-set'
							src={item?.bannerImage2}
							alt='Second slide'
						/>
					</Carousel.Item>
					<Carousel.Item interval={3000}>
						<img
							className=' Carousel-items-set'
							src={item?.bannerImage3}
							alt='Third slide'
						/>
					</Carousel.Item>
					<Carousel.Item interval={3000}>
						<img
							className=' Carousel-items-set'
							src={item?.bannerImage4}
							alt='Third slide'
						/>
					</Carousel.Item>
					<Carousel.Item interval={3000}>
						<img
							className=' Carousel-items-set'
							src={item?.bannerImage5}
							alt='Third slide'
						/>
					</Carousel.Item>
				</Carousel>
				<div className=' bg-white'>
					<div className='container-fluid Joinus-section'>
						<h3 className='Joinus-section-header'>
							<Marquee style={applyStyles}>
								<div dangerouslySetInnerHTML={{ __html: item?.joinUs }}></div>
							</Marquee>
						</h3>
					</div>
				</div>
			</section>
		</>
	);
};

export default Home_banner;
Home_banner.propTypes = {
	item: PropTypes.object.isRequired,
};

import React from 'react';
import BlackLoader from '../assets/BlackGif.gif';
import '../Styles/Spinner.css';

const Spinners = () => {
	return (
		<div className='container-fluid w-100 spinner d-flex justify-content-center align-items-center'>
			<img src={BlackLoader} alt='' />
		</div>
	);
};

export default Spinners;

export const convertDateTime = (fromDateTimeString, toDateTimeString) => {
    const convertToFormattedString = (dateTimeString) => {
      const originalDateTime = new Date(dateTimeString);
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      };
      let formattedDateTime = originalDateTime.toLocaleString('en-US', options);
      formattedDateTime = formattedDateTime.replace(/\//g, '-');
    formattedDateTime = formattedDateTime.replace(',', '');
    
    return formattedDateTime;
    };
  
    const formattedFromDateTime = convertToFormattedString(fromDateTimeString);
    const formattedToDateTime = convertToFormattedString(toDateTimeString);
    const FromDate = formattedFromDateTime?.split(' ')[0]
    const FromDateChange = formattedFromDateTime?.split(' ')?.slice(1,3)?.toLocaleString()?.replace(',', ' ')
    const ToDateChanges = formattedToDateTime?.split(' ')?.slice(1,3)?.toLocaleString()?.replace(',', ' ')
    return `<span class="textblue">
    ${FromDate} 
    </span>
    <span class="textRed">
    ${FromDateChange} 
    </span>
        <span class="textblue mx-1">
        To
        </span>
        <span class="textRed">
        ${ToDateChanges}
        </span>
    `;
  };
import React from 'react';
import '../Styles/Common.css';
import { useSelector } from 'react-redux';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import Spinner from '../component/Spinners';

const ContactUs = () => {
	const { HomePageList } = useSelector((state) => state.festivWebsite);

	const applyStyles = {
		fontFamily: HomePageList?.fontFamily,
		color: HomePageList?.fontColor,
	};

	const mapStyles = {
		height: '381px',
		width: '100%',
	};
	const center = {
		lat: parseFloat(HomePageList?.latitude),
		lng: parseFloat(HomePageList?.longitude),
	};
	return (
		(HomePageList === '' && <Spinner />) || (
			<div className='bannerBackground'>
				<div className='container-fluid BackGroundBlur' style={applyStyles}>
					<div className='container contactMain  px-3 py-3'>
						<div className='row ticketDetailbanner'>
							<h2> Contact Us</h2>
						</div>
						<hr />
						<div className='row'>
							<div className='col-lg-5'>
								<div className='footer-infoItem'>
									<ul>
										<li className='tel'>
											<a href={`tel:${HomePageList?.contactPhoneNo}`}>
												{HomePageList?.contactPhoneNo}
											</a>
										</li>
										<li className='address'>{HomePageList?.locationName}</li>
										<li className='mail'>
											<a
												href={`mailto:${HomePageList?.contactAdminEnquiryEmail}`}>
												{HomePageList?.contactAdminEnquiryEmail}
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className='row'>
							<div className='col-lg-12'>
								{/* <GoogleMap
									center={center}
									zoom={4}
									mapContainerStyle={mapStyles}
									draggable={true}>
									<Marker
										position={{
											lat: parseFloat(HomePageList?.latitude),
											lng: parseFloat(HomePageList?.longitude),
										}}>
										<InfoWindow
											position={{
												lat: parseFloat(HomePageList?.latitude),
												lng: parseFloat(HomePageList?.longitude),
											}}>
											<>
												<div className='fs-6  fw-normal text-dark'>
													{' '}
													{HomePageList?.locationName}
												</div>
											</>
										</InfoWindow>
									</Marker>
								</GoogleMap> */}
								{/* <MapSection
        item={HomePageList}
        /> */}
								{/* <section className='iframeMap'> */}
								<iframe
									src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3036.0686165258508!2d-74.49374262509016!3d40.451618253516955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c40cf8fb263b%3A0x17928a20a682f338!2s630%20US-1%2C%20North%20Brunswick%20Township%2C%20NJ%2008902%2C%20USA!5e0!3m2!1sen!2sin!4v1718624393340!5m2!1sen!2sin'
									width='100%'
									title='location'
									height='500px'
									allowfullscreen=''
									loading='lazy'></iframe>
								{/* </section> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default ContactUs;

import React, { useCallback, useEffect } from 'react';
import '../Styles/TicketRedeem.css';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	RedeemData,
	RedeemTicketScan,
	errorMessage,
	successMessage,
	loadingStatus,
	RedeemStatus,
} from '../Redux/Slice';
import Spinner from '../component/Spinners';
import FestivImage from '../assets/logo.png';
import { errTitle, scc, poscent, errIcon, sccIcon, BtnCanCel, BtnGreat } from '../Constant';
import Swal from 'sweetalert2';
import { convertDateTime } from '../TimeConvert';

const RedeemTicket = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { TicketReedemDate, success, status } = useSelector((state) => state.festivWebsite);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get('orderid');

	useEffect(() => {
		if (id) {
			dispatch(RedeemData(id));
		} else {
			navigate('/');
		}
	}, [id, dispatch, navigate]);

	const Notification = useCallback(
		(val, tit, pos, ico, btn) => {
			Swal.fire({
				position: `${pos}`,
				title: `${tit}`,
				text: `${val}`,
				icon: `${ico}`,
				confirmButtonText: `${btn}`,
			}).then(function () {
				dispatch(errorMessage({ errors: '' }));
				dispatch(successMessage({ successess: '' }));
				dispatch(loadingStatus({ loadingStatus: false }));
				dispatch(RedeemStatus({ RedeemStatus: '' }));
			});
		},
		[dispatch],
	);

	useEffect(() => {
		if (status === 'Success') {
			const sccMsg = 'Your Ticket Successfully Scanned ! Enjoy The Event';
			Notification(sccMsg, scc, poscent, sccIcon, BtnGreat);
			dispatch(RedeemData(id));
		}

		if (status === 'Failure') {
			const errMsg = success;
			Notification(errMsg, errTitle, poscent, errIcon, BtnCanCel);
			dispatch(RedeemData(id));
		}
	}, [success, dispatch, status, Notification, id]);

	const handleRedeemTicket = (ticketRedeemId) => {
		const val = {
			uniqueId: ticketRedeemId,
		};
		dispatch(RedeemTicketScan(val));
	};

	return (
		(TicketReedemDate === '' && <Spinner />) || (
			<div className='bannerBackground'>
				<Container fluid className='BackGroundBlurRedeem'>
					<Container className='bannerMainRedeem mt-5 py-2 px-2'>
						{TicketReedemDate.length > 0 &&
							TicketReedemDate?.map((item, redeemIndex) => (
								<div className='container RedeemContain' key={redeemIndex}>
									<Row className='d-flex justify-content-center align-items-center'>
										<Col lg={6} md={6} sm={12} className='LogoFestivImage'>
											<img src={FestivImage} alt='' />
										</Col>
										<Col lg={6} md={6} sm={12} className='ticketQRImage'>
											<img src={item?.qrcode} alt='' />
										</Col>
									</Row>
									<Row>
										<Col lg={12} md={12} sm={12}>
											<div className='TicketRedeemId'>
												<h1>Ticket Id : {item?.ticketId}</h1>
											</div>
										</Col>
									</Row>
									<Row className='w-100 d-flex justify-content-center flex-wrap'>
										<Col
											lg={12}
											md={6}
											sm={12}
											className='w-100 d-flex justify-content-center flex-wrap'>
											<Row className='w-100 d-flex justify-content-center flex-wrap'>
												<Col lg={6} className='text-center'>
													<Row className='flex'>
														<Col lg={4}>
															<h6>Event Name :</h6>
														</Col>
														<Col lg={7} className='ticketRedeemHead'>
															<h6>{item?.eventName}</h6>
														</Col>
													</Row>
												</Col>
												<Col lg={6} className='text-center'>
													<Row className='flex'>
														<Col lg={5} className='ticketRedeemHead'>
															<h6>Event Location :</h6>
														</Col>
														<Col lg={7}>
															<h6>{item?.locationName}</h6>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
										<Col
											lg={10}
											md={6}
											sm={12}
											className='w-100 d-flex justify-content-center flex-wrap'>
											<Row className='w-100 d-flex justify-content-center flex-wrap'>
												<Col lg={6} className='text-center'>
													<Row className='flex'>
														<Col lg={4}>
															<h6>Ticket Name :</h6>
														</Col>
														<Col lg={7} className='ticketRedeemHead'>
															<h6>{item?.ticketName}</h6>
														</Col>
													</Row>
												</Col>
												<Col lg={6} className='text-center'>
													<Row className='flex'>
														<Col lg={5} className='ticketRedeemHead'>
															<h6>Ticket Type :</h6>
														</Col>
														<Col lg={7}>
															<h6>{item?.ticketType}</h6>
														</Col>
													</Row>
												</Col>
											</Row>
										</Col>
									</Row>
									{item?.message === 'Current Event' && (
										<>
											<Row className='w-100 d-flex justify-content-center flex-wrap'>
												<Col
													lg={12}
													md={6}
													sm={12}
													className='w-100 d-flex justify-content-center flex-wrap'>
													<Row className='w-100 d-flex justify-content-center flex-wrap'>
														<Col lg={4} className='text-start'>
															<Row className='flex'>
																<Col lg={10}>
																	<h6>Ticket Scan Limit:</h6>
																</Col>
																<Col
																	lg={2}
																	className='ticketRedeemHead'>
																	<h6>
																		# {item?.totalScanlimit}
																	</h6>
																</Col>
															</Row>
														</Col>
														<Col lg={4} className='text-center'>
															<Row className='flex'>
																<Col lg={10}>
																	<h6>
																		Ticket Balance Scan Limit:
																	</h6>
																</Col>
																<Col
																	lg={2}
																	className='ticketRedeemHead'>
																	<h6>
																		# {item?.balanceScanlimit}
																	</h6>
																</Col>
															</Row>
														</Col>
														<Col lg={4} className='text-end'>
															<Row className='flex'>
																<Col lg={6}>
																	<h6>Order Number:</h6>
																</Col>
																<Col
																	lg={6}
																	className='ticketRedeemHead'>
																	<h6># {item?.orderNumber}</h6>
																</Col>
															</Row>
														</Col>
													</Row>
												</Col>
											</Row>
											<Row className='w-100 d-flex justify-content-center'>
												<Col lg={8}>
													<Row className='flex'>
														<Col lg={12} className='text-center'>
															<h3 className='fw-bold'>
																Ticket Use Date
															</h3>
														</Col>
														<Col
															lg={12}
															className='ticketRedeemHead mt-2 text-center'>
															{item?.redemption?.map(
																(item, dateIndex) => (
																	<div key={dateIndex}>
																		<h6
																			dangerouslySetInnerHTML={{
																				__html: convertDateTime(
																					item?.redemDateAndTimeFrom,
																					item?.redemDateAndTimeTo,
																				),
																			}}></h6>
																	</div>
																),
															)}
														</Col>
													</Row>
												</Col>
											</Row>
											<Row className='tiketRedeembutton'>
												<button
													disabled={item?.balanceScanlimit === 0}
													className={
														item?.balanceScanlimit === 0
															? 'DisabledBtn'
															: 'tiketRedeembuttonClick'
													}
													onClick={() => {
														handleRedeemTicket(item?.ticketId);
													}}>
													{item?.balanceScanlimit === 0
														? 'Ticket Scan Limit Exceeded,Please Buy New Ticket !...'
														: 'Click here to Use Ticket'}
												</button>
											</Row>
										</>
									)}
									{item?.message === 'Scanlimit is Zero' && (
										<Row className='Liveannouncement py-1'>
											<Col lg={12}>
												<div>
													<h5>
														Ticket Scan Limit Exceeded,Please Buy New
														Ticket !...
													</h5>
												</div>
											</Col>
										</Row>
									)}
									{item?.message === 'Event is reschedule' && (
										<Row className='Liveannouncement'>
											<Col lg={12}>
												<div>
													<h5>Event is Rescheduled</h5>
												</div>
											</Col>
										</Row>
									)}
									{item?.message === 'Event is inActive' && (
										<Row className='Liveannouncement'>
											<Col lg={12}>
												<div>
													<h5>
														This Event & Ticket are not live. Please get
														in touch with us for further assistance
													</h5>
												</div>
											</Col>
										</Row>
									)}
									{item?.message === 'Event and Ticket is Rescheduled' && (
										<Row className='Liveannouncement'>
											<Col lg={12}>
												<div>
													<h5>
														Changes to the event and ticket details are
														coming soon. Keep an eye out for updates.
													</h5>
												</div>
											</Col>
										</Row>
									)}
									<Row>
										<h4 className='text-center NoteRedeem '>
											{' '}
											<strong className='text-danger'>Note :</strong> The
											Ticket Must Be Redeemed Only By An Authorized Person.
										</h4>
									</Row>
									<Row>
										<p className='text-center disclamierRedeem '>
											<strong className='text-danger'>Note</strong>: The code
											on this ticket allows redemption of the item described
											on this ticket, and it will be scanned on the QR code or
											click “Use Ticket” button by authorized personnel for
											validation and authenticity. For e-tickets, the “Use
											Ticket” Button MUST be clicked and validated by the
											authorized personnel. Do not make additional copies of
											this ticket; duplicates will be rejected. Purchase of
											this ticket by a third party is not authorized and
											carries a risk of being fraudulent. Event reserves the
											right to require photo ID for entry. This ticket is a
											revocable license to fulfill this item. Management may,
											without refund, revoke this license and refuse admission
											or redemption for non-compliance with these terms or for
											disorderly conduct. Unlawful sale or attempted sale
											subjects tickets to revocation without refund. Tickets
											obtained from unauthorized sources may be invalid, lost,
											stolen or counterfeit and so are void. You voluntarily
											assume all risks whether occurring prior to, during and
											after this event. You agree to release the organization,
											facility, participants, Sigur Solutions and their
											respective affiliates and representatives from
											responsibility and related claims. You grant
											unrestricted license to use your image or likeness in
											photograph or video by the event and its respective
											agents. Once a purchase is made, there will be no
											refunds or exchanges granted, meaning that once a ticket
											is bought, there is no option for reimbursement,
											regardless of the circumstances. Service fees charged
											based on tickets price and ticket quantities are
											non-refundable regardless of the circumstances. All
											ticket sales are final. HAVE A GREAT TIME!
										</p>
									</Row>
								</div>
							))}
					</Container>
				</Container>
			</div>
		)
	);
};

export default RedeemTicket;

import { AboutData } from '../Redux/Slice';
import '../Styles/Common.css';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Spinner from '../component/Spinners';

const About = () => {
	const { AboutAdminData } = useSelector((state) => state.festivWebsite);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(AboutData());
	}, [dispatch]);

	const applyStyles = {
		fontFamily: AboutAdminData?.fontFamily,
		color: AboutAdminData?.fontColor,
	};

	return (
		(AboutAdminData === '' && <Spinner />) || (
			<div className='bannerBackground'>
				<div className='container-fluid BackGroundBlur ' style={applyStyles}>
					<div className='container bannerMain'>
						<div className='row ticketDetailbanner'>
							<div className='bannerImg'>
								<img src={AboutAdminData?.aboutBannerImage} alt='' />
							</div>
						</div>
						<div className='row d-flex justify-content-center align-items-center py-3 px-3 aboutTitle'>
							<h2 className='text-center fw-bold'>ABOUT US</h2>
						</div>
						<div className='row d-flex justify-content-center align-items-center py-3 px-3'>
							<div
								className='col-lg-9 aboutBackContent'
								dangerouslySetInnerHTML={{
									__html: AboutAdminData?.description,
								}}></div>
						</div>
						<div className='row aboutContent'>
							<div className='col-lg-4 col-md-5'>
								<img src={AboutAdminData?.aboutImage1} alt='' />
							</div>
							<div className='col-lg-4 col-md-5'>
								<img src={AboutAdminData?.aboutImage2} alt='' />
							</div>
							<div className='col-lg-4 col-md-5'>
								<img src={AboutAdminData?.aboutImage3} alt='' />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	);
};

export default About;

import React from 'react';
import Star from '../assets/start-icon.png';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { convertDateTime } from '../TimeConvert';

const Festival_Highlights = ({ item }) => {
	const applyStyles = {
		fontFamily: item?.fontFamily,
		color: item?.fontColor,
	};

	const navigate = useNavigate();

	const handleEventPage = (id) => {
		navigate(`/eventdetails/${id}`);
	};

	return (
		<>
			<section className='Highlight-Section'>
				<div className='container my-2 d-flex justify-content-center align-items-center'>
					<div className='row d-flex justify-content-center align-items-center'>
						<div className='col-lg-12 d-flex justify-content-center align-items-center'>
							<a href='/ticketdetail'>
								<button className='btn-txt1 ticketButtonMain'>
									{' '}
									Click here to GET TICKETS{' '}
								</button>
							</a>
						</div>
					</div>
				</div>

				<div className='container ' style={applyStyles}>
					<div className='row HightlightWidget'>
						<div className='col-12 d-flex justify-content-center align-items-center felx-row Highlight-Header'>
							<img src={Star} alt='star'></img>
							<span
								dangerouslySetInnerHTML={{
									__html: item?.festivalHighlightsTitle,
								}}></span>
							<img src={Star} alt='star'></img>
						</div>
						<div className='row HightlightUpcoming  '>
							{item?.festivalHighlightsEvents?.length > 0 ? (
								item?.festivalHighlightsEvents?.map((items, index) => (
									<div
										key={index}
										onClick={() => {
											handleEventPage(items?.eventId);
										}}
										className='col-lg-4 col-md-12 col-sm-12  cardEventSection'>
										<div className='row cardFlexCss'>
											<div className='col-lg-12 col-md-4 col-sm-12 imgDivUpcoming'>
												<img src={items?.eventImage} alt='' />
											</div>
											<div className='col-lg-12 col-md-7 col-sm-12 cardEventContent'>
												<div className='card-widget-title py-2'>
													<u>{items?.eventName}</u>
												</div>	
												<div className='row px-2'>
													{items?.eventSchedule?.map((item, inds) => (
														<div
															className='HomeCardDate px-0 col-lg-12'
															key={inds}>
															<span className='text-dark fw-bold d-flex'>
																<p
																	dangerouslySetInnerHTML={{
																		__html: convertDateTime(
																			item?.eventDateAndTimeFrom,
																			item?.eventDateAndTimeTo,
																		),
																	}}></p>
															</span>
														</div>
													))}
												</div>
											</div>
										</div>
									</div>
								))
							) : (
								<div className='fw-bold text-center'>
									<h3>Upcoming Events</h3>
								</div>
							)}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Festival_Highlights;

Festival_Highlights.propTypes = {
	item: PropTypes.object.isRequired,
};

import './App.css';
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './homePages/Home';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { EventPageData, HomePageData } from './Redux/Slice';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EventDetails from './EventPage/EventDetails';
import EventList from './EventPage/EventList';
import Ticket from './Ticketpage/Ticket';
import Spinner from './component/Spinners';
import BuyTicket from './Ticketpage/BuyTicket';
import Sponser from './Common/sponser';
import Vendor from './Common/vendor';
import About from './Common/About';
import ContactUs from './Common/ContactUs';
import FestivalHours from './Common/FestivalHours';
import PrivacyPolicy from './Common/PrivacyPolicy';
import ScrollToTop from './component/ScrollToTop';
import RedeemTicket from './TicketRedeem/RedeemTicket';

// const Home = React.lazy(() => import('./homePages/Home'));
// const EventDetails = React.lazy(() => import('./EventPage/EventDetails'));
// // const EventList = React.lazy(() => import('./EventPage/EventList'));
// const BuyTicket = React.lazy(() => import('./Ticketpage/BuyTicket'));
// const Ticket = React.lazy(() => import('./Ticketpage/Ticket'));
// const Sponser = React.lazy(() => import('./Common/sponser'));
// const Vendor = React.lazy(() => import('./Common/vendor'));
// const About = React.lazy(() => import('./Common/About'));
// const ContactUs = React.lazy(() => import('./Common/ContactUs'));
// const FestivalHours = React.lazy(() => import('./Common/FestivalHours'));
// const PrivacyPolicy = React.lazy(() => import('./Common/PrivacyPolicy'));
// const RedeemTicket = React.lazy(() => import('./TicketRedeem/RedeemTicket'));
// const ScrollToTop = React.lazy(() => import('./component/ScrollToTop'));
// const Spinner = React.lazy(() => import('./component/Spinners'));

const App = () => {
	const { HomePageList } = useSelector((state) => state.festivWebsite);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(HomePageData());
		dispatch(EventPageData());
	}, [dispatch]);

	return (
		(HomePageList === '' && <Spinner />) || (
			<Router>
				<ScrollToTop />
				<Header items={HomePageList} />
				<Routes>
					<Route path='/' element={<Home items={HomePageList} />}></Route>
					<Route path='/event' element={<EventList />}></Route>
					<Route exact path='/eventdetails/:id' element={<EventDetails />}></Route>
					<Route
						exact
						path='/eventdetails/:id/ticketdetails'
						element={<Ticket />}></Route>
					<Route exact path='/ticketdetail' element={<Ticket />}></Route>
					<Route exact path='/buyticket' element={<BuyTicket />}></Route>
					<Route path='/sponsor' element={<Sponser />}></Route>
					<Route path='/vendor' element={<Vendor />}></Route>
					<Route exact path='/about' element={<About />}></Route>
					<Route exact path='/contact' element={<ContactUs />}></Route>
					<Route exact path='/festivalhours' element={<FestivalHours />}></Route>
					<Route exact path='/privacypolicy' element={<PrivacyPolicy />}></Route>
					<Route exact path='/tickets' element={<RedeemTicket />}></Route>
					<Route exact path='*' element={<Home items={HomePageList} />}></Route>
				</Routes>
				<Footer items={HomePageList} />
			</Router>
		)
	);
};

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Animation_Card = ({ item }) => {
	return (
		<>
			<section className='AnimationCards'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-12 AnimationContainer'>
							<Link className='card-widget-item' to='/event'>
								<div className='card-widget-item'>
									<div
										className='image-container-AD lozad'
										style={{ backgroundImage: `url(${item?.events})` }}
										data-loaded='true'></div>
									<div className='card-content'>
										<span className='card-widget-title'>Events</span>
										<div className='buttonwrap'></div>
									</div>
								</div>
							</Link>
							<Link className='card-widget-item' to='/sponsor'>
								<div className='card-widget-item'>
									<div
										className='image-container-AD lozad'
										style={{ backgroundImage: `url(${item?.sponsorship})` }}
										data-loaded='true'></div>
									<div className='card-content'>
										<span className='card-widget-title'>sponsorship</span>
										<div className='buttonwrap'></div>
									</div>
								</div>
							</Link>
							<Link className='card-widget-item' to='/vendor'>
								<div className='card-widget-item'>
									<div
										className='image-container-AD lozad'
										style={{ backgroundImage: `url(${item?.vendors})` }}
										data-loaded='true'></div>
									<div className='card-content'>
										<span className='card-widget-title'>Vendors</span>
										<div className='buttonwrap'></div>
									</div>
								</div>
							</Link>
						</div>
					</div>
					<div className='row'>
						<div className='col-lg-8 d-flex justify-content-center w-100'>
							<a href='/ticketdetail'>
								<button className='btn-txt1 ticketButtonMain'>
									{' '}
									Click here to GET TICKETS{' '}
								</button>
							</a>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Animation_Card;

Animation_Card.propTypes = {
	item: PropTypes.object.isRequired,
};

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaymentGateway, errorMessage, loadingStatus, successMessage } from '../Redux/Slice';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../Styles/Ticket.css';
import Fest from '../assets/fest.png';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import {
	scc,
	poscent,
	successPayMessage,
	errorPayMessage,
	PaymentErrTitle,
	errIcon,
	sccIcon,
	BtnCanCel,
	BtnGreat,
} from '../Constant';
import Card from 'react-credit-cards';
import { formatCreditCardNumber } from '../utils';
import 'react-credit-cards/es/styles-compiled.css';

const BuyTicket = () => {
	const { success, error } = useSelector((state) => state.festivWebsite);
	const cart = JSON.parse(sessionStorage.getItem('cart'));

	const initialData = cart ? cart : [];

	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState(initialData);

	useEffect(() => {
		if (!selectedData.length > 0) {
			navigate('/ticketdetail');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedData]);

	const { EventPageList } = useSelector((state) => state.festivWebsite);

	const applyStyles = {
		fontFamily: EventPageList?.fontFamily,
		color: EventPageList?.fontColor,
	};

	const Notification = useCallback((val, tit, pos, ico, btn) => {
		Swal.fire({
			position: `${pos}`,
			title: `${tit}`,
			text: `${val}`,
			icon: `${ico}`,
			confirmButtonText: `${btn}`,
		}).then(function () {
			navigate('/');
			dispatch(errorMessage({ errors: '' }));
			dispatch(successMessage({ successess: '' }));
			dispatch(loadingStatus({ loadingStatus: false }));
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (success === 'success') {
			success && Notification(successPayMessage, scc, poscent, sccIcon, BtnGreat);
		}
		if (error !== 'something went wrong' || error !== 'Please try again') {
			error && Notification(errorPayMessage, PaymentErrTitle, poscent, errIcon, BtnCanCel);
		}
	}, [error, success, Notification]);

	var element = 0;
	var ticketPrice = 0;
	var merchandiseFees = 0;
	var salesTax = 0;
	var processingFees = 0;
	var creditCardFees = 0;
	var otherFees = 0;
	var totalQuantity = 0;

	for (let index = 0; index < selectedData?.length; index++) {
		const num = selectedData[index]?.quantity;
		element +=
			parseInt(num) * parseFloat(selectedData[index]?.fees?.totalTicketPrice).toFixed(2);
		ticketPrice +=
			parseInt(num) * parseFloat(selectedData[index]?.fees?.ticketPrice?.price).toFixed(2);
		salesTax +=
			parseInt(num) * parseFloat(selectedData[index]?.fees?.salesTaxDollar).toFixed(2);
		processingFees +=
			parseInt(num) * parseFloat(selectedData[index]?.fees?.processingFeesDollar).toFixed(2);
		otherFees +=
			parseInt(num) * parseFloat(selectedData[index]?.fees?.otherFeesDollar).toFixed(2);
		creditCardFees +=
			parseInt(num) * parseFloat(selectedData[index]?.fees?.creditCardFeesDollar).toFixed(2);
		merchandiseFees +=
			parseInt(num) * parseFloat(selectedData[index]?.fees?.merchandiseFeesDollar).toFixed(2);
		// eslint-disable-next-line no-unused-vars
		totalQuantity += +selectedData[index]?.quantity;
	}

	var TotalPrices = element.toFixed(2);

	const handelEditSelect = async (
		quantity,
		fees,
		eventIndex,
		ticketIndex,
		feesIndex,
		ticketname,
		eventName,
		item,
	) => {
		const ticketType = item?.fees?.ticketType;
		const ticketId = item?.ticketId;
		const eventId = item?.eventId;
		const redemption = item?.redemption;
		const scanlimit = item?.scanlimit;
		const locationId = item?.locationId;
		const address = item?.address;
		const purchaselimit = item?.purchaselimit;
		const locationName = item?.locationName;

		const selectedInfo = {
			eventIndex,
			ticketIndex,
			feesIndex,
			fees,
			quantity,
			ticketname,
			eventName,
			ticketType,
			ticketId,
			eventId,
			locationId,
			address,
			locationName,
			redemption,
			scanlimit,
			purchaselimit,
		};

		const selectedIndex = selectedData.findIndex(
			(item) =>
				item.eventIndex === eventIndex &&
				item.ticketIndex === ticketIndex &&
				item.feesIndex === feesIndex,
		);
		if (selectedIndex !== -1) {
			setSelectedData((prevState) => {
				const newArray = [...prevState];
				newArray[selectedIndex] = selectedInfo;
				sessionStorage.setItem('cart', JSON.stringify(newArray));
				return newArray;
			});
		} else {
			setSelectedData((prevState) => {
				const newArray = [...prevState, selectedInfo];
				sessionStorage.setItem('cart', JSON.stringify(newArray));
				return newArray;
			});
		}
	};

	const handleRemove = (eventIndex, ticketIndex, feesIndex) => {
		setSelectedData((prevState) => {
			const updatedData = prevState.filter(
				(item) =>
					item.eventIndex !== eventIndex ||
					item.ticketIndex !== ticketIndex ||
					item.feesIndex !== feesIndex,
			);
			// Save updated selectedData to sessionStorage
			sessionStorage.setItem('cart', JSON.stringify(updatedData));
			return updatedData;
		});
	};

	const navigate = useNavigate();

	// eslint-disable-next-line no-unused-vars
	const [issuer, setIssuer] = useState('');
	const [focused, setFocused] = useState('');

	const handleCallback = ({ issuer }, isValid) => {
		if (isValid) {
			setIssuer(issuer);
		}
	};

	const handleInputFocus = ({ target }) => {
		setFocused(target.name);
	};

	const formik = useFormik({
		initialValues: {
			name: '',
			cardNumber: '',
			expiryDate: '',
			cardCode: '',
			email: '',
			zipcode: '',
			signup: false,
		},
		validate: (values) => {
			const errors = {};

			if (!values.name) {
				errors.name = 'Name is Required*';
			}
			const cardNumberDigits = formatCreditCardNumber(values.cardNumber);

			if (!cardNumberDigits) {
				errors.cardNumber = 'Card Number is Required*';
			} else if (cardNumberDigits.length < 14 || cardNumberDigits.length > 16) {
				errors.cardNumber = 'Card number must be between 14 and 16 digits';
			}

			if (!values.expiryDate) {
				errors.expiryDate = 'Expiry Date is Required*';
			} else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(String(values.expiryDate))) {
				errors.expiryDate = 'Please enter a valid expiry date in MM/YY format.';
			}

			if (!values.cardCode) {
				errors.cardCode = 'Cvv is Required*';
			} else if (!/^\d{3}$/.test(values.cardCode)) {
				errors.cardCode = 'CVV must be a 3-digit number';
			}

			if (!values.email) {
				errors.email = 'Email is Required*';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
				errors.email = 'Must be valid e-mail';
			}

			if (!values.zipcode) {
				errors.zipcode = 'Zip code is Required*';
			} else if (!/^\d{5}$/.test(values.zipcode)) {
				errors.zipcode = 'Must be exactly 5 digits';
			}

			if (Object.keys(errors).length === 0) {
				formik.setStatus({ isSubmitting: true });
			}

			return errors;
		},
		onSubmit: (values, { setSubmitting, resetForm }) => {
			values.amount = parseFloat(TotalPrices);
			values.selectedData = selectedData;
			values.zipcode = values.zipcode.toString();
			values.confirmEmail = values.email;
			dispatch(PaymentGateway(values));
			setTimeout(() => {
				setSubmitting(false);
			}, 2000);
			resetForm();
		},
	});

	return (
		<div className='TicketMain'>
			<div className='TicketBlur' style={applyStyles}>
				<Container className='d-flex ticketContainer flex-column py-3 px-3'>
					<Row className='mb-1 w-100 checkMain '>
						<Col lg={6} className='text-start fw-bold checkTitle'>
							<h5> CHECKOUT</h5>
						</Col>
						<Col lg={6} className='text-end checkBtn'>
							<Button
								className='ticketDetailBtn'
								onClick={() => {
									navigate(-1);
								}}>
								{' '}
								continue shopping
							</Button>
						</Col>
					</Row>
					<hr className='border-2' />
					<Row>
						{selectedData?.map((item, index) => (
							<div key={index}>
								<Row
									className='d-flex align-items-center justify-content-center'
									key={index}>
									<Col lg={12} className='cartCard'>
										<Row className='d-flex align-items-center justify-content-center'>
											<Col lg={2} md={2}>
												<img src={Fest} alt='' />
											</Col>
											<Col lg={10}>
												<Row className='ticketListContent'>
													<Col lg={3} md={6} sm={2}>
														<div>
															<p className='mt-1 mb-1'>Event Name</p>
															<h5 className='fw-bold'>
																{item?.eventName}
															</h5>
														</div>
													</Col>
													<Col lg={3} md={6}>
														<p className='mt-1 mb-1'>Ticket Name</p>
														<h5 className='fw-bold'>
															{item?.ticketname}
														</h5>
													</Col>
													<Col lg={3} md={6} sm={2}>
														<p className='mt-1 mb-1'>Ticket Type</p>
														<div className='d-flex'>
															<h5 className='fw-bold'>
																{item?.fees?.ticketType}
															</h5>
															<div className='px-2'>
																<select
																	value={
																		selectedData.find(
																			(items) =>
																				items.eventIndex ===
																					item?.eventIndex &&
																				items.ticketIndex ===
																					item?.ticketIndex &&
																				items.feesIndex ===
																					item?.feesIndex,
																		)?.quantity || ''
																	}
																	name=''
																	id=''
																	onChange={(e) =>
																		handelEditSelect(
																			e.target.value,
																			item?.fees,
																			item?.eventIndex,
																			item?.ticketIndex,
																			item?.feesIndex,
																			item?.ticketname,
																			item?.eventName,
																			item,
																		)
																	}>
																	<option value=''>Qty</option>
																	{Array.from(
																		{
																			length: item?.purchaselimit,
																		},
																		(_, index) => index + 1,
																	).map((value) => (
																		<option
																			key={value}
																			value={value}>
																			{value}
																		</option>
																	))}
																</select>
															</div>
														</div>
													</Col>
													<Col lg={3} md={6} sm={2}>
														<div className='ticketBgList'>
															<div className='d-flex justify-content-between'>
																<div>
																	<p> Total Price</p>
																	<h5 className='px-2 fw-bold'>
																		{' '}
																		${' '}
																		{(
																			parseFloat(
																				item?.fees
																					?.ticketPrice
																					?.price,
																			) *
																			parseFloat(
																				item?.quantity,
																			)
																		).toFixed(2)}
																	</h5>
																</div>
																<div className='py-2'>
																	<Button
																		type='button'
																		className='rounded-circle btn btn-dark'
																		onClick={() => {
																			handleRemove(
																				item?.eventIndex,
																				item?.ticketIndex,
																				item?.feesIndex,
																			);
																		}}>
																		X
																	</Button>
																</div>
															</div>
														</div>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
						))}
					</Row>
					<Row>
						<h4 className='textRed'>
							<u> IMPORTANT PAYMENT INSTRUCTIONS : </u>
						</h4>
						<Col lg={10}>
							<ul className='paymentInstruction'>
								<li className='textRed py-1'>
									<h5>
										<strong>
											CHARGES ON YOUR CREDIT CARD WILL BE FROM SIGUR
											SOLUTIONS.
										</strong>
									</h5>
								</li>
								<li className='textRed py-1'>
									Payments must be made by Bank issued Credit or Debit Cards ONLY.
								</li>
								<li className='textRed py-1'>
									Please ensure that you enter all the required details accurately
									for your order to be processed successfully.
								</li>
								<li className='textRed py-1'>
									Please also enter your correct email address to receive your
									e-tickets.
								</li>
								<li className='textRed py-1'>
									<strong>
										Once your payment has been processed successfully, you will
										receive <br /> an email confirmation with the e-tickets from
										no-reply@festivtickets.com.
									</strong>
								</li>
								<li className='textRed py-1'>
									<strong>
										If you have not received this email in your inbox, please
										check your spam folder and search for
										no-reply@festivtickets.com.
									</strong>
								</li>
							</ul>
							<div>
								<ul className='paymentInstruction'>
									<strong className='textRed py-1'>NOTE</strong>
									<li className='textRed py-1'>
										<strong>
											We do not accept any credit or debit cards issued by
											Venmo , Cashapp or PayPal.
										</strong>
									</li>
									<li className='textRed py-1'>
										<strong>We DO NOT Accept ANY GIFT CARD PAYMENTS</strong>
									</li>
								</ul>
							</div>
						</Col>
					</Row>
					<Row className='ticketPaymentSection'>
						<Col lg={3} md={6} sm={12} className='my-4 ticketPaymentCol'>
							<Row className='payment my-4'>
								<Card
									number={formik.values.cardNumber}
									name={formik.values.name}
									expiry={formik.values.expiryDate}
									cvc={formik.values.cardCode}
									focused={focused}
									callback={handleCallback}
								/>
								<form onSubmit={formik.handleSubmit}>
									<div className='form-group inputPad'>
										<small>Email</small>
										<input
											type='text'
											name='email'
											className='form-control'
											placeholder='Enter your Email'
											required
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											onFocus={handleInputFocus}
											value={formik.values.email}
										/>
										{formik.touched.email && formik.errors.email && (
											<div>
												<p>{formik.errors.email}</p>
											</div>
										)}
									</div>
									<div className='form-group inputPad'>
										<small>Name on card</small>
										<input
											type='text'
											name='name'
											className='form-control'
											placeholder='Name'
											required
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											onFocus={handleInputFocus}
											value={formik.values.name}
										/>
										{formik.touched.name && formik.errors.name && (
											<div>
												<p>{formik.errors.name}</p>
											</div>
										)}
									</div>
									<div className='form-group inputPad'>
										<small>Card Number</small>
										<input
											type='tel'
											name='cardNumber'
											className='form-control'
											placeholder='Card Number'
											pattern='[\d| ]{16,22}'
											maxLength='19'
											required
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.cardNumber}
											onFocus={handleInputFocus}
										/>
										{formik.touched.cardNumber && formik.errors.cardNumber && (
											<div>
												<p>{formik.errors.cardNumber}</p>
											</div>
										)}
									</div>
									<div className='form-group inputPad'>
										<small>Expiration Date ( MM / YY )</small>
										<input
											type='text'
											name='expiryDate'
											className='form-control'
											placeholder='Valid MM/YY'
											pattern='\d\d/\d\d'
											required
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.expiryDate}
											onFocus={handleInputFocus}
										/>
										{formik.touched.expiryDate && formik.errors.expiryDate && (
											<div>
												<p>{formik.errors.expiryDate}</p>
											</div>
										)}
									</div>
									<div className='form-group inputPad'>
										<small>Card CVV</small>
										<input
											type='text'
											name='cardCode'
											className='form-control'
											placeholder='Card CVV'
											required
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.cardCode}
											onFocus={handleInputFocus}
										/>
										{formik.touched.cardCode && formik.errors.cardCode && (
											<div>
												<p>{formik.errors.cardCode}</p>
											</div>
										)}
									</div>
									<div className='form-group inputPad'>
										<small>Zip Code</small>
										<input
											type='text'
											name='zipcode'
											className='form-control'
											placeholder='Zip Code'
											required
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.zipcode}
											onFocus={handleInputFocus}
										/>
										{formik.touched.zipcode && formik.errors.zipcode && (
											<div>
												<p>{formik.errors.zipcode}</p>
											</div>
										)}
									</div>
									<div className='form-group inputPad d-flex'>
										<div>
											<input
												type='checkbox'
												name='signup'
												id='signup'
												onChange={formik.handleChange}
											/>
										</div>
										<div>
											<h6 className='fw-bold px-2'>
												Sign up for email updates from Festiv
											</h6>
										</div>
									</div>
									<div className='form-actions'>
										<button
											type='submit'
											onClick={formik.handleSubmit}
											className='ticketDetailBtnBuy'>
											Pay Now {TotalPrices}
										</button>
									</div>
								</form>
							</Row>
						</Col>
						<Col lg={7} sm={12} md={12} className='ticketSummary'>
							<Row className='d-flex justify-content-end align-items-start'>
								<Col lg={12} className='d-flex justify-content-end flex-column'>
									<div className='py-2 px-3 my-2 text-center border fs-4 rounded text-dark fw-bold ticketSummaryHeading'>
										Order Summary
									</div>
									<table className='my-2'>
										<thead>
											<tr className='ticketTotal'>
												<th>Total</th>
												<th>Price</th>
											</tr>
										</thead>
										<tbody>
											<tr className='ticketTotal'>
												<td>Ticket Price</td>
												<td> ${ticketPrice.toFixed(2)} </td>
											</tr>
											{creditCardFees !== 0 && (
												<tr className='ticketTotal'>
													<td>Convenience Fees</td>
													<td> ${creditCardFees.toFixed(2)} </td>
												</tr>
											)}
											{processingFees !== 0 && (
												<tr className='ticketTotal'>
													<td>Processing Fee</td>
													<td> ${processingFees.toFixed(2)} </td>
												</tr>
											)}
											{merchandiseFees !== 0 && (
												<tr className='ticketTotal'>
													<td>Merchandising Fee</td>
													<td> ${merchandiseFees.toFixed(2)} </td>
												</tr>
											)}
											{otherFees !== 0 && (
												<tr className='ticketTotal'>
													<td>Service Fees</td>
													<td> ${otherFees.toFixed(2)} </td>
												</tr>
											)}
											{salesTax !== 0 && (
												<tr className='ticketTotal'>
													<td>Sales Tax</td>
													<td> ${salesTax.toFixed(2)} </td>
												</tr>
											)}
										</tbody>
									</table>
									<hr />
									<div className='w-100 d-flex justify-content-between '>
										<div>
											<h5 className='fw-bold'>Grand Total </h5>
										</div>
										<div className='mx-2'>
											<h5 className='fw-bold'>${element.toFixed(2)}</h5>
										</div>
									</div>
									<div className='scrollArrow'>
										<div className='d-flex justify-content-center'>
											<button type='button'>
												Scroll Down to Make Payment
											</button>
										</div>
										<div className='chevroncontainer'>
											<div className='chevron'></div>
											<div className='chevron'></div>
											<div className='chevron'></div>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default BuyTicket;
